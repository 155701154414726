export default class RehabilitationReason{
    Reason = {};
    constructor(reasonId, reasonName, date, stillInPain){
        this.Reason = {
            reasonId: reasonId || "",
            reasonName: reasonName || "",
            date: date || new Date(),
            stillInPain: stillInPain || "false"
        }
    }
}