import React from 'react';
import dateformat from 'dateformat';
// CSS Imports
import '../css/PatientNote.css';


class PatientNote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showArray : [false, false, false, false, false]
        };
   
    }

    componentDidMount() {
        this.setState({
            date: new Date()

        });
    }

    componentWillUnmount() {
        
    }

    HideShow(count){
        this.state.showArray[count] = !this.state.showArray[count];
        
        this.setState({
            showArray:this.state.showArray

        });
    }

    render() {
        return ( <div className="flex-container">
        <div>
            <img className="noteImage" src={require('../images/notes_doctor.png')}></img>
            <h3 style={{fontSize:20, marginTop:5}}>σημειώματα</h3>
        </div>
        <table className="noteTable" style = {{textAlign: "left"}}> 
            <tbody>
                <tr className="tableRow">
                    <td colSpan="2">
                        <p onClick={()=>this.HideShow(0)} className="pDetails">ΛΟΙΜΩΞΕΙΣ</p>
                        {this.state.showArray[0] && <p className="textPNote" style={{fontWeight:"normal"}}>Hello World! This is just a beta text
                        and is only there for testing reasons,
                        so when everything is done please remove
                        me.</p>}
                    </td>
                </tr>
                <tr className="tableRow">
                    <td colSpan="2">
                        <p onClick={()=>this.HideShow(1)} className="pDetails">ΑΡΧΙΚΗ ΕΚΤΙΜΗΣΗ ΠΑΘΟΛΟΓΟΥ</p>
                        {this.state.showArray[1] && <p className="textPNote" style={{fontWeight:"normal"}}>Hello World! This is just a beta text
                        and is only there for testing reasons,
                        so when everything is done please remove
                        me.</p>}
                    </td>
                </tr>
                <tr className="tableRow">
                    <td colSpan="2">
                        <p onClick={()=>this.HideShow(2)} className="pDetails">ΕΠΙΠΛΟΚΕΣ ΚΑΤΑ ΤΗΝ ΝΟΣΗΛΕΙΑ</p>
                        {this.state.showArray[2] && <p className="textPNote" style={{fontWeight:"normal"}}>Hello World! This is just a beta text
                        and is only there for testing reasons,
                        so when everything is done please remove
                        me.</p>}
                    </td>
                </tr>
                <tr className="tableRow">
                    <td colSpan="2">
                        <p onClick={()=>this.HideShow(3)} className="pDetails">ΦΥΣΙΟΘΕΡΑΠΕΙΕΣ</p>
                        {this.state.showArray[3] && <p className="textPNote" style={{fontWeight:"normal"}}>Hello World! This is just a beta text
                        and is only there for testing reasons,
                        so when everything is done please remove
                        me.</p>}
                    </td>
                </tr>
                <tr className="tableRow">
                    <td colSpan="2">
                        <p onClick={()=>this.HideShow(4)} className="pDetails">ΟΔΗΓΙΕΣ</p>
                        {this.state.showArray[4] && <p className="textPNote" style={{fontWeight:"normal"}}>Hello World! This is just a beta text
                        and is only there for testing reasons,
                        so when everything is done please remove
                        me.</p>}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
        );
    }
}

export default PatientNote;
