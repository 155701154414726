import React from 'react';
import PatientProfile from '../models/PatientProfile.js';
import dateformat from 'dateformat';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import PatientInfo from './PatientInfo';
import url from './PatientPath';
import Preferences from '../models/Preferences.js';
import RehabilitationReason from '../models/RehabilitationReason.js';
import Meal from '../models/Meal.js';
import Extras from '../models/Extras.js';
import NumberFormat from 'react-number-format';
// CSS imports
import '../css/PatientInfo.css';
import '../css/Tab.css';


class PatientInputInfo extends React.Component {

    constructor(props) {
        super(props);

        let patientK = new PatientProfile("");
        console.log(patientK);
        this.patientLink = "https://hm-api-v2.entranet.us:7656/rea/patient"; // save patient
        this.state = {
            userId: "",
            selectedBuilding: this.props.building,
            patient : patientK,
            selectedPatientMeal: new Meal(), // for patient
            room: "",
            dropdownSelected: {music: "", news: ""},
            dropdownPreferences: {music: ["", "Ελληνική", "Κλασική", "Μπλουζ", "Ποπ", "Ροκ", "Τζαζ"], news: ["", "Αθλητικά", "Πολιτική", "Διεθνή", "Οικονομία"]},
            preferences: {music: [], news: []},
            rehabilitationReason: new RehabilitationReason(),
            extras: new Extras(),
            meals: ['', 'LEVINE ΤΑΚΤΙΚΟ', 'LEVINE ΔΙΑΒΗΤΙΚΟ', 'LEVINE ΑΝΑΛΟ', 'LEVINE ΥΠΕΡΛΕΥΚΩΜΑΤΟΥΧΟ', 'LEVINE ΥΠΕΡΘΕΡΜΙΔΙΚΟ', 'LEVINE ΔΙΑΡΡΟΙΑ', 'LEVINE ΑΛΙΠΟ',
            'ΥΔΑΡΗΣ',
            'ΜΑΛΑΚΗ ΤΑΚΤΙΚΟ', 'ΜΑΛΑΚΗ ΑΝΑΛΟ', 'ΜΑΛΑΚΗ ΑΝΑΛΟ ΔΙΑΒΗΤΙΚΟ', 'ΜΑΛΑΚΗ ΔΙΑΒΗΤΙΚΟ', 'ΜΑΛΑΚΗ ΕΛΑΦΡΥ', 'ΜΑΛΑΚΗ ΑΛΙΠΟ', 'ΜΑΛΑΚΗ ΥΠΕΛΕΥΚΩΜΑΤΟΥΧΟ', 'ΜΑΛΑΚΗ ΥΠΕΡΘΕΡΜΙΔΙΚΟ', 'ΜΑΛΑΚΗ SINTROM', 'ΜΑΛΑΚΗ ΧΟΛΗ', 'ΜΑΛΑΚΗ SINTROM ΔΙΑΡΡΟΙΑ', 'ΜΑΛΑΚΗ ΔΙΑΡΡΟΙΑ', 'ΜΑΛΑΚΗ ΔΙΑΒΗΤΙΚΟ ΔΙΑΡΡΟΙΑ', 'ΜΑΛΑΚΗ ΔΙΑΒΗΤΙΚΟ ΝΕΦΡΟΠΑΘΗ',
            'ΤΑΚΤΙΚΟ', 'ΤΑΚΤΙΚΟ ΔΙΑΒΗΤΙΚΟ', 'ΤΑΚΤΙΚΟ ΝΕΦΡΟΠΑΘΗ', 'ΤΑΚΤΙΚΟ ΜΑΛΑΚΗ ΝΕΦΡΟΠΑΘΗ', 'ΤΑΚΤΙΚΟ ΔΙΑΒΗΤΙΚΟ ΝΕΦΡΟΠΑΘΗ', 'ΤΑΚΤΙΚΟ ΑΝΑΛΟ', 'ΤΑΚΤΙΚΟ ΑΝΑΛΟ ΔΙΑΒΗΤΙΚΟ', 'ΤΑΚΤΙΚΟ ΥΠΕΡΛΕΥΚΩΜΑΤΟΥΧΟ', 'ΤΑΚΤΙΚΟ ΥΠΕΡΘΕΡΜΙΔΙΚΟ', 'ΤΑΚΤΙΚΟ ΑΛΙΠΟ', 'ΤΑΚΤΙΚΟ SINTROM', 'ΤΑΚΤΙΚΟ ΥΠΟΘΕΡΜΙΔΙΚΟ', 'ΤΑΚΤΙΚΟ ΥΠΕΡΠΡΩΤΕΪΝΙΚΟ', 'ΤΑΚΤΙΚΟ ΔΙΑΒΗΤΙΚΟ ΧΟΛΗ', 'ΤΑΚΤΙΚΟ ΔΙΑΡΡΟΙΑ', 'ΤΑΚΤΙΚΟ ΔΙΑΒΗΤΙΚΟ ΔΙΑΡΡΟΙΑ', 'ΤΑΚΤΙΚΟ ΕΛΑΦΡΑ', 'ΤΑΚΤΙΚΟ SINTROM ΔΙΑΡΡΟΙΑ',
            'ΑΛΕΣΜΕΝΟ ΤΑΚΤΙΚΟ', 'ΑΛΕΣΜΕΝΟ ΕΛΑΦΡΑ', 'ΑΛΕΣΜΕΝΟ ΔΙΑΒΗΤΙΚΟ', 'ΑΛΕΣΜΕΝΟ ΑΛΙΠΟ', 'ΑΛΕΣΜΕΝΟ ΔΙΑΡΡΟΙΑ', 'ΑΛΕΣΜΕΝΟ ΑΝΑΛΟ', 'ΑΛΕΣΜΕΝΟ SINTROM'
            ],
        };
    }

    handleChange = (date,key) =>{
        this.state.patient[key] = date;
        this.setState({
            patient: this.state.patient
        });
    }
    handleDropdownChange(event, key) {
        this.state.patient[key] = event.target.value;
        this.setState({
            patient: this.state.patient
        });
    }

    handleChangePatient = (event, key) => {
        this.state.patient[key] = event.target.value
        this.setState({
            patient:this.state.patient
        });
    }
    handleChangeInputArea = (event, key) => {
        this.state.extras[key] = event.target.value;
        this.setState({
            extras: this.state.extras
        });
    }

    componentDidUpdate(prevProps) {
        console.log(this.props.patient);
        if (prevProps.patient !== this.props.patient) {
            // patient meal type
            let selectedPatientMeal = null;
                if (typeof this.props.patient.meal !=="undefined")
                    selectedPatientMeal = this.props.patient.meal[this.props.patient.meal.length-1]; // the selectedPatientMeal is the last one in the array
            if (this.props.patient.preferences !== "undefined"){
                this.state.preferences.music = this.props.patient.preferences.music || [];
                this.state.preferences.news = this.props.patient.preferences.news || [];
            }
            // rehabilitation reason
            if (typeof this.props.patient.rehabilitationReason == "undefined"){
                this.props.patient.rehabilitationReason = new RehabilitationReason();
            }
            this.setState({
                patient:this.props.patient,
                preferences: this.state.preferences ||{music: [], news: []},
                //rehabilitationReason: this.props.patient.rehabilitationReason || new RehabilitationReason(),
                extras: this.props.patient.extras || new Extras(),
                selectedPatientMeal: selectedPatientMeal ? selectedPatientMeal : new Meal(),
                userId: this.props.patient.userId || ""
            });
        }
        if (prevProps.room !== this.props.room) {
            this.setState({
                room:this.props.room
            });
        }
        console.warn(this.state.extras);
    }


    componentDidMount() {
        if(this.props.patient!=null){
            this.state.userId = this.props.patient.userId || "";
            if (this.props.patient.preferences.music !== undefined && this.props.patient.preferences.news !== undefined){
                this.state.preferences.music = this.props.patient.preferences.music || [];
                this.state.preferences.news = this.props.patient.preferences.news || [];
                this.setState({
                    preferences: this.state.preferences
                })
            }
            // patient meal type
            let selectedPatientMeal = null;
                if (typeof this.props.patient.meal !=="undefined")
                    selectedPatientMeal = this.props.patient.meal[this.props.patient.meal.length-1]; // the selectedPatientMeal is the last one in the array

            // rehabilitation reason
            if (typeof this.props.patient.rehabilitationReason == "undefined"){
                this.props.patient.rehabilitationReason = new RehabilitationReason();
            }

            this.setState({
                patient:this.props.patient,
                extras: this.props.patient.extras || new Extras(),
                selectedPatientMeal: selectedPatientMeal ? selectedPatientMeal : new Meal(),
                userId: this.state.userId
            });
        }

        if(this.props.room!=null){
            this.setState({
                room:this.props.room
            });
        }

    }

    componentWillUnmount() {
    }

    handlePreferencesDropdownChange(event, key){
        this.state.dropdownSelected[key] = event.target.value;
        this.setState({
            dropdownSelected: this.state.dropdownSelected
        });
    }

    addToPreferences(key){
        if (this.state.dropdownSelected[key] === "") return;
        for (let i=0;i<this.state.preferences[key].length;i++){
            if (this.state.dropdownSelected[key] === this.state.preferences[key][i])
                return;
        }

        this.state.preferences[key].push(this.state.dropdownSelected[key]);
        this.state.patient.preferences = new Preferences(this.state.preferences.music, this.state.preferences.news);
        this.setState({
            preferences: this.state.preferences,
            patient: this.state.patient
        })
    }

    createInput = (description, key)=>{
        return this.createRow(description, <input className="pInput" type='text' 
        value={ typeof this.state.patient[key] !== "undefined" ? this.state.patient[key] : ""} 
        onChange={(event)=>this.handleChangePatient(event, key)}
        placeholder={description} ></input>);
    }
    createInputArea = (description, key)=>{
        return this.createRow(description, <textarea rows={5} cols={60} className="pInput" type='textarea' 
        value={this.state.extras[key]}
        onChange={(event)=>this.handleChangeInputArea(event, key)}
        placeholder={description} ></textarea>);
    }
    createDropdownYesNo = (description, key)=>{
        return (this.createRow(description, <form>
                <label>
                <select value={this.state.patient[key]} defaultValue="" onChange={(event)=>this.handleDropdownChange(event, key)}>
                    <option value=""></option>
                    <option value="ΝΑΙ">ΝΑΙ</option>
                    <option value="ΟΧΙ">ΟΧΙ</option>
                </select>
                </label>
            </form>)
          );
    }
    createInputNumber = (description, key)=>{
        return this.createRow(description, <NumberFormat className="pInput" allowLeadingZeros={true}
        value={typeof this.state.patient[key] !== "undefined" ? this.state.patient[key] : ""}
        onChange={(event)=>this.handleChangePatient(event, key)}
        placeholder={description}></NumberFormat>)
    }

    createInputUserID(description){
        let disable = false;
        if (typeof this.state.patient.userId !== "undefined" && this.state.patient.userId !== "")
            disable = true;
        return this.createRow(description, <NumberFormat className="pInput" disabled={disable} allowLeadingZeros={true}
        value={typeof this.state.userId !== "undefined" ? this.state.userId : ""}
        onChange={(event)=>this.handleChangeUserId(event)}
        placeholder={description}></NumberFormat>)
    }

    handleChangeUserId(event){
        this.state.userId = event.target.value;
        this.setState({
            userId: this.state.userId
        });
    }

    createRow = (description,value)=>{
        return <tr><td className="pLeftInput">{description}</td><td className="pRightInput">{value}</td></tr>;
    }

    createDate = (description, key) =>{
        return this.createRow(description,  <DatePicker dateFormat="dd/MM/yyyy" selected = {typeof this.state.patient[key] !== "undefined"?
        Date.parse(this.state.patient[key]): new Date("01/01/1970")} onChange = {(event)=> this.handleChange(event, key)}/> );
    }

    patientInfoSaveBtn(){
        if (typeof this.state.userId == "undefined" || this.state.userId == "")
            return alert("Πρέπει να εισάγετε User ID ασθενή");
        if (typeof this.state.patient.AMKA == "undefined" || this.state.patient.AMKA == "")
            return alert("Πρέπει να εισάγετε ΑΜΚΑ ασθενή");

        if (this.props.duplicateCheck("userId", this.state.userId, this.state.patient._id))
            return alert("Το USER ID του ασθενή αντιστοιχεί σε υπάρχων ασθενή");
        if (this.props.duplicateCheck("AMKA", this.state.patient.AMKA, this.state.patient._id))
            return alert("Το ΑΜΚΑ του ασθενή αντιστοιχεί σε υπάρχων ασθενή");

        this.state.patient.userId = this.state.userId;
        if(typeof this.state.patient._id !=="undefined"){
            this.state.patient.extras = this.state.extras;
            if (typeof this.state.patient.meal !== 'undefined')
                this.state.patient.meal = [this.state.selectedPatientMeal];
            else
                this.state.patient.meal = [this.state.selectedPatientMeal];
            this.setState({patient: this.state.patient});
            axios.post(url+"/patient",
            this.state.patient,{headers: {"Authorization": localStorage.getItem("token")}}
            )
            .then((res)=> {
                let patient = res.data;

                patient.date = new Date();
                console.log(patient);
                this.savePatientToHousemateServer();
                axios.post(url+"/messages",
                patient,{headers: {"Authorization": localStorage.getItem("token")}}
                )
                .then((res)=> {

                    let patient = res.data;
                    delete patient._id;
                    patient.user = this.state.patient._id;
                    patient.room = this.props.room;
                    console.log(patient);
                    axios.post(url+"/user",
                    patient,{headers: {"Authorization": localStorage.getItem("token")}}
                    )
                    .then((res) =>{
                        console.log(res.data);
                        //
                        let token = localStorage.getItem('hmToken');
                        let buildingID = localStorage.getItem('buildingID');
                        axios.post("https://hm-api-v2.entranet.us:7656/housemate/rea/room",{
                            buildingID:buildingID,
                            token:token,
                            uid:this.state.patient.userId,
                            origin:"4"
                        },{headers: {"Authorization": localStorage.getItem("token")}})
                        .then()
                        .catch();
                        
                    }).catch(err=>{
                    console.log(err);
                    });
                }).catch(err=>{
                console.log(err);
                });
            }).catch(err=>{
                console.log(err);
            });
        }else{
            axios.post(url+"/patient",
            this.state.patient,{headers: {"Authorization": localStorage.getItem("token")}}
            )
            .then((res)=> {
            this.state.patient._id = res.data._id;
            this.state.patient.userId = res.data.userId;
            this.state.patient.meals = res.data.meals;
            this.state.patient.medicine = res.data.medicine;
            //take patients
            this.setState({patient:this.state.patient});
                let patient = res.data;
                console.log(patient);

                this.savePatientToHousemateServer();
            }).catch(err=>{
            console.log(err);
            });
        }

        
        // Save Rehabilitation Reason
        //this.rehabSaveBtn();
    }

    savePatientToHousemateServer(){
        let devId = "";
        if(this.state.selectedBuilding.devices.length>0){
            devId = this.state.selectedBuilding.devices[0].deviceID;
        }
        axios.put(this.patientLink,
            {
                userID: this.state.patient.userId,
                buildingID: this.state.selectedBuilding.buildingID,
                deviceID: devId
            },{}
            )
            .then((res)=> {
            }).catch(err=>{
                console.log(err);
            });
    }
    createPreferencesDropdown(description, key){
        const listItems = this.state.dropdownPreferences[key].map((d) => <option key={d} value={d}>{d}</option>)
        return [<tr key="description">
            <td colSpan="2" className="pLeftInput">{description}</td>
            </tr>,
            <tr key="preferences" >
                <td>
                    <form>
                        <label>
                        <select onChange={(event)=>this.handlePreferencesDropdownChange(event, key)}>
                            {listItems}
                        </select>
                        </label>
                    </form>
                </td>
                <td>
                    <button className="addBtn" onClick={()=>this.addToPreferences(key)}>
                            <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img><p className="txtBtn">ΠΡΟΣΘΗΚΗ</p>
                    </button>
                </td>
            </tr>];
    }

    createPreferencesList(key, index){
        return <p style={{display: 'inline'}}>
            {this.state.preferences[key][index]}&nbsp;&nbsp;
        </p>;
    }

    // --------- From PatientRehabTab ---------
    handleChangeRehab = (event,key) =>{
        this.state.patient.rehabilitationReason.Reason[key] = event.target.value;
        this.setState({
            patient: this.state.patient
        });
    }
    handleDateChangeRehab = (date,key) =>{
        this.state.patient.rehabilitationReason.Reason[key] = date;
        this.setState({
            patient: this.state.patient
        });
    }
    createInputRehab = (description, key)=>{
        return this.createRowRehab(description, <input className="pInput" type='text'
        value={typeof this.state.patient.rehabilitationReason !== "undefined" ? this.state.patient.rehabilitationReason.Reason[key] : ""}
        onChange={(event)=>this.handleChangeRehab(event, key)}
        placeholder={description} ></input>);
    }
    createRowRehab = (description,value)=>{
        return <tr><td className="pLeftInput">{description}</td><td className="pRightInput">{value}</td></tr>;
    }
    createDateRehab = (description, key) =>{
        return this.createRowRehab(description,  <DatePicker dateFormat="dd/MM/yyyy" selected = {typeof  this.state.patient.rehabilitationReason.Reason[key] !== "undefined"?
        Date.parse(this.state.patient.rehabilitationReason.Reason[key]): new Date()} onChange = {(event)=> this.handleDateChangeRehab(event, key)}/> );
    }
    createDropdownYesNoRehab = (description, key)=>{
        return (this.createRowRehab(description, <form>
                <label>
                <select value={this.state.patient.rehabilitationReason.Reason[key]} defaultValue="" onChange={(event)=>this.handleChangeRehab(event, key)}>
                    <option value=""></option>
                    <option value="true">ΝΑΙ</option>
                    <option value="false">ΟΧΙ</option>
                </select>
                </label>
            </form>)
        );
    }
    // --------- From PatientRehabTab ---------
    createMealsDropdown(description){
        const listItems = this.state.meals.map((d) => <option key={d} value={d}>{d}</option>);
        return this.createRow(
                description,
                <select style={{minWidth: 270}} value={this.state.selectedPatientMeal.type} onChange={(event)=>this.handleMealsDropdownChange(event)}>
                        {listItems}
                </select>
        );
    }
    handleMealsDropdownChange(event){
        this.state.selectedPatientMeal = new Meal(event.target.value, new Date());
        this.setState({
            selectedPatientMeal: new Meal(event.target.value, new Date())
        });
    }

    render() {
        return ( <div className="flex-container">

        <div className="pageHead">
            <img className="infoImage" src={require('../images/profile_doctor.png')}></img>
            <h3>Στοιχεία Ασθενούς</h3>
        </div>

        <table className="patientTable" style={{textAlign: "left"}}>
            <thead>
                <tr>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {this.createInput("Ονοματεπώνυμο:", "name")}
                {this.createInput("Οικογενειακή κατάσταση:", "familyStatus")}
                {this.createInput("Όνομα συζύγου:", "spouseName")}
                {this.createInput("Διεύθυνση κατοικίας:", "address")}
                {this.createDate("Ημερομηνία Γέννησης:", "dateOfBirth")}
                {this.createInputNumber("Τηλέφωνο:", "phone")}
                {this.createInput("Επικοινωνία:", "contact")}
                <tr><td colSpan='2'><div className="border"></div></td></tr>

                {/* {this.createInputNumber("User ID:", "userId")} */}
                {this.createInputUserID("User ID:")}
                {this.createInputNumber("AMKA:", "AMKA")}
                {this.createInput("Ασφάλιση:", "insurance")}

                    {/* Replaced with RehabilitationReason */}
                {/* {this.createDate("Ημερομηνία εισητηρίου:", "InDate")}
                {this.createInput("Διάγνωση Εισόδου:", "diagnosis")} */}

                {this.createInput("Θεραπών Ιατρός:", "doctor")}
                {this.createInput("Ειδικότητα Ιατρού:", "speciality")}
                {this.createInput("Κατηγορία περιστατικού:", "incident")}
                {this.createDate("Ημερομηνία περιστατικού:", "incidentDate")}
                {this.createDate("Ημερομηνία εξιτηρίου:", "OutDate")}
                {this.createDropdownYesNo("Διαβητικός:","diabetic")}
                {this.createMealsDropdown("Τύπος γευμάτων ασθενή:")}
                <tr><td colSpan='2'><div className="border"></div></td></tr>

                {/* RehabilitationReason */}
                {this.createInputRehab("Λόγος αποκατάστασης:", "reasonName")}
                {this.createDateRehab("Ημερομηνία εισόδου:", "date")}
                {this.createDropdownYesNoRehab("Πόνος:","stillInPain")}
                <tr><td colSpan='2'><div className="border"></div></td></tr>

                {this.createPreferencesDropdown("Προτίμηση μουσικής:","music")}
                <tr>
                    <td>
                        Λίστα: {this.state.preferences.music.map((pref, index) => this.createPreferencesList("music", index))}
                    </td>
                </tr>
                {this.createPreferencesDropdown("Προτίμηση ειδήσεων:","news")}
                <tr>
                    <td>
                        Λίστα: {this.state.preferences.news.map((pref, index) => this.createPreferencesList("news", index))}
                    </td>
                </tr>

                <tr><td colSpan='2'><div className="border"></div></td></tr>
                <tr>
                    <td colSpan="2" className="center">
                        <button className="addBtn" style={{fontSize:20, fontWeight:"bold", textAlign:"center"}} onClick={() =>{
                            this.patientInfoSaveBtn();
                            }}>ΑΠΟΘΗΚΕΥΣΗ
                        </button>
                    </td>

                </tr>

                <tr className="pInfo" >
                    <td colSpan="2">
                        {this.createInputArea("Σημειώσεις:", "notes")}
                        {this.createInputArea("Αναλυτική Διάγνωση Εισόδου:", "moreInfo")}
                        {this.createInputArea("Ατομικό Αναμνηστικό:", "medicalHistory")}
                        {this.createInputArea("Λοιμώξεις:", "infections")}
                        {this.createInputArea("Αρχική Εκτίμηση Παθολόγου:", "prognosis")}
                        {this.createInputArea("Επιπλοκές κατά την Νοσηλεία:", "complications")}
                    </td>
                </tr>
            </tbody>
        </table>
            </div>
        );
    }
}

export default PatientInputInfo;
