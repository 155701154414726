function getBuildingID(){
    return new Promise((resolve, reject) => {
      return resolve(localStorage.getItem('buildingID'));
    })
}

function getToken(){
  return new Promise((resolve, reject) => {
    return resolve(localStorage.getItem('token'));
  })
    
}

const responseDataCheck = (response) => {
  console.log('in responseDataCheck')
    return new Promise((resolve, reject) => {
      response.then(responseData => {
        if (responseData.hasOwnProperty("state") && responseData.state === 0) {
          console.log("Response is resolved");
          console.log(responseData);
          return resolve(responseData);
        } else {
          if (responseData.hasOwnProperty("data") && responseData.data !== null && responseData.data.hasOwnProperty("text")) {
            console.log(responseData.data.text);
            return reject(responseData.data.text);
          }
          console.log("Response gets rejected");
          return reject(responseData);
        }
      });
    });
  };
  


export function FetchData(url, data){
  console.log('fetchData: ', url,data)
  return getToken().then((signedToken) => {
    data.headers = { 'Content-Type': 'application/json', 'Authorization': signedToken };
    return fetch(url, data).then((response) => responseDataCheck(response.json()));
  });

}

export const getBuildingsForUserData = (signedToken) => {
  return {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': signedToken },
  };
}



export default {getBuildingID, getToken, FetchData}



