import React from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import url from './PatientPath';
// CSS Imports
import '../css/PatientInfo.css';
import '../css/PatientNote.css';
import '../css/Tab.css';
import Temperature from '../models/Temperature';
// Unused Imports
/*
import dateformat from 'dateformat';
import url from './PatientPath';
*/

class PatientBiometric extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            inputTimestamp: new Date(),
            inputTemperature: "",
            temperature: this.props.patient.temperature || [],
            tempInfo:[
                /*{
                    date:'10.01.2020',
                    time:'07.30',
                    temperature:'38'
                },
                {
                    date:'10.01.2020',
                    time:'12.00',
                    temperature:'39'
                }*/],
            pressure:[
                /*{
                    date:'10.01.2020',
                    time:'08.00',
                    systolic: '12',
                    diastolic:'8'
                },
                {
                    date:'10.01.2020',
                    time:'12.00',
                    systolic:'12',
                    diastolic:'8'
                }*/]
            };

    }

    componentDidMount() {
        this.setState({
            date: new Date(),
        });

        /*axios.get(url+"/messages/"+this.props.patient._id)
            .then((res) =>{
                console.log(res.data);
            }).catch(err=>{
            console.log(err);
            });*/
        //http://192.168.0.70:8081
        if(this.props.patient!=null){
            axios.post("https://hm-api.entranet.us:7656"+"/main/sensors",
            {
                buildingID:this.props.buildingID,
                uid:this.props.patient.userId
            })
                .then((res) =>{
                    this.state.pressure = [];
                    this.state.tempInfo = [];
                    let data = res.data.data;
                    console.log(data[0]);
                    data.forEach(d=>{
                        let count = d.count;
                        if(count>0){
                            let results = d.results;
                            results.forEach(r=>{
                                this.state.pressure.push({
                                    date:this.getDate(r.time),
                                    time:this.getTime(r.time),
                                    systolic:r.systolic,
                                    diastolic:r.diastolic
                                })
                                this.state.tempInfo.push({
                                    date:this.getDate(r.time),
                                    time:this.getTime(r.time),
                                    temperature:r.heartrate,
                                })
                            })
                        }
                    })
                    this.setState({pressure:this.state.pressure,tempInfo:this.state.tempInfo});
                }).catch(err=>{
                console.log(err);
                });
        }
    }

    getDate(d){
        let date = new Date(d);
        let s = date.getDate()+"."+(date.getMonth()+1)+"."+date.getFullYear();
        return s;
    }

    getTime(d){
        let date = new Date(d);
        let s = date.getHours()+"."+date.getMinutes();
        return s;
    }

    componentWillUnmount() {
    }

    handleTimeChange(date){
        this.setState({
            inputTimestamp: date
        });
    }

    handleChangeTemp(event){
        this.setState({
            inputTemperature: event.target.value
        });
    }

    createRow = (description,value)=>{
        return <tr><td className="pLeftInput">{description}</td><td className="pRightInput">{value}</td></tr>;
    }

    createTimeStamp = (description) => {
        return this.createRow(description, <DatePicker
                value={this.state.inputTimestamp}
                selected={this.state.inputTimestamp}
                onChange={(date)=> this.handleTimeChange(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="d MMMM yyyy h:mm aa"
            />);
    }

    createInputNumberTemp = (description) =>{
        return this.createRow(description, <NumberFormat className="numberInput"placeHolder="°C"
            value={this.state.inputTemperature} onChange={(event)=>this.handleChangeTemp(event)}>
            </NumberFormat>);
    }

    tempSaveBtn(){
        this.state.temperature.push(new Temperature(this.state.inputTimestamp, this.state.inputTemperature));
        this.props.patient.temperature = this.state.temperature;
        this.setState({
            temperature: this.state.temperature
        });

        this.props.patient.temperature = this.state.temperature;
        axios.put(url+"/patient",
        this.props.patient,{headers: {"Authorization": localStorage.getItem("token")}})
        .then(function (patient) {
            console.log(patient);
        }).catch(err=>{
            console.log(err);
        });
    }

    createTempTable(index){
        var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
        let tempReading = this.state.temperature[index].tempReading || 0.0;
        var fontColor = {color: "black"};
        if (tempReading>=36.9 && tempReading<=38.0)
            fontColor = {color: "orange"};
        else if (tempReading>=38.1)
            fontColor = {color: "red"};
        return <p style={fontColor}>
                    &nbsp;-&nbsp;&nbsp;
                    {/* new Date is used because Date format is different here and from mondo db */}
                    {new Date(this.state.temperature[index].timestamp).toLocaleDateString("el-GR", options)}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.state.temperature[index].tempReading}
                    &nbsp;°C
                </p>;
    }

    render() {
        return ( <div className="flex-container">

            <div className="pageHead">
                <img className="biometricImage" src={require('../images/vitals_doctor.png')}></img>
                <h3>Βιομετρικές Εξετάσεις</h3>
            </div>

            <table className="biometricTable">
                <thead>
                {/*<tr>
                    <th colSpan='2' style={{paddingBottom:20, fontWeight:'bold', fontSize:20, textAlign:'left'}}>ΘΕΡΜΟΚΡΑΣΙΑ</th>
                    <th colSpan='2' style = {{paddingBottom: 20, paddingLeft:20}}>
                        <div className = "borders" >
    
                        </div>
                    </th>
                </tr>*/}
                </thead>
                <tbody>
                {/*this.state.tempInfo.map((entry,index)=><tr key={index+entry.toString()} className="tempEntries"><td>{entry.date}</td>
                    <td style={{paddingLeft:35}}>{entry.time}</td><td></td><td style={{textAlign:'right'}}>{entry.temperature}</td></tr>)*/}

                 <tr>
                    <th colSpan='2' style={{paddingBottom:20,paddingTop:20 ,fontWeight:'bold', fontSize:20}}>ΑΡΤΗΡΙΑΚΗ ΠΙΕΣΗ</th>
                    <th colSpan='2'style = {{paddingBottom: 20,paddingTop:20, paddingLeft:20}}>
                        <div className = "borders" >

                        </div>
                    </th>
                </tr>
                {this.state.pressure.map((entry,index)=><tr key={entry.systolic+index} className="tempEntries"><td>{entry.date}</td>
                <td style={{paddingLeft:35}}>{entry.time}</td><td style={{textAlign:'center',paddingLeft:150,paddingRight:150}}>Συστολική πίεση:{entry.systolic}</td><td style={{textAlign:'right'}}>Διαστολική πίεση:{entry.diastolic}</td></tr>)}

                <tr>
                    <th colSpan='2' style={{paddingBottom:20,paddingTop:20 ,fontWeight:'bold', fontSize:20}}>ΘΕΡΜΟΚΡΑΣΙΑ</th>
                    <th colSpan='2'style = {{paddingBottom: 20,paddingTop:20, paddingLeft:20}}>
                        <div className = "borders"></div>
                    </th>
                </tr>
                <tr>
                    <td colSpan="3">
                        <div className="pageHead">
                            {/* Timestamp Input */}
                            {this.createTimeStamp("Ημ/μηνία Θερμομέτρησης:")}

                            {/* Temperature Input*/}
                            {this.createInputNumberTemp("Μέτρηση:")}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" className="center">
                        {/* Save Button */}
                        <button className="addBtn" style={{fontSize:20, fontWeight:"bold", textAlign:"center"}}
                            onClick={()=>{
                                this.tempSaveBtn();
                            }}>ΑΠΟΘΗΚΕΥΣΗ
                        </button>
                    </td>
                </tr>
                <tr className="myBackground">
                        <td colSpan="4">
                            {this.state.temperature.map((temp, index) => this.createTempTable(index))}
                        </td>
                </tr>
                </tbody>

            </table>

        </div>
        );
    }
}

export default PatientBiometric;
