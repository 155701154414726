import React from 'react';
import axios from 'axios';
import Treatment from '../models/Treatment.js';
import url from './PatientPath';
import DatePicker from 'react-datepicker';
// CSS imports
import '../css/PatientInfo.css';
import '../css/PatientNote.css';
import '../css/Tab.css';

class PatientTreatment extends React.Component{
    constructor(props) {
        super(props);
        let treatmentK = new Treatment();
        this.state = {
            treatments: [treatmentK]
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.patient !== this.props.patient) {
            if(this.props.patient!=null && typeof this.props.patient.treatments !=="undefined"){
            this.setState({
                treatments:this.props.patient.treatments
            });
            }
        }
    }
    componentDidMount() {
        if(this.props.patient!=null){
            axios.get(url+"/treatment/user/"+this.props.patient._id,
                    {headers: {"Authorization": localStorage.getItem("token")}}
            ).then(res=> {
            let us = res.data;
            console.warn(us);
            if (res.data === undefined || res.data.user_id === undefined){
                this.setState({treatments: [new Treatment()]});
            }else{
                this.setState({treatments: res.data.treatments});
            }
          }).catch(err=>{
            console.log(err);
          });
            this.setState({
                patient:this.props.patient
            });
        }
    }
    componentWillUnmount() {
    }
    getPropertyValue = (obj1, dataToRetrieve) => {
        return dataToRetrieve
          .split('.') // split string based on `.`
          .reduce(function(o, k) {
            return o && o[k]; // get inner property if `o` is defined else get `o` and return
          }, obj1) // set initial value as object
      }
    setPropertyValue = (obj1, dataToRetrieve, value) => {
        return dataToRetrieve
        .split('.') // split string based on `.`
        .reduce(function(o, k, index, r) {
            if (index === r.length-1){
            o[k] = value;
            console.warn(o[k]);
            }
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
        }, obj1) // set initial value as object
    }
    handleChange = (event,key, index) =>{
        this.setPropertyValue(this.state.treatments[index], key, event.target.value);
        this.setState({
            treatments: this.state.treatments
        });
    }
    handleDateChange = (date,key, index) =>{
        this.setPropertyValue(this.state.treatments[index], key, date);
        this.setState({
            treatment: this.state.treatments
        });
    }
    handleDropdownChange(event, key, index) {
        this.setPropertyValue(this.state.treatments[index], key, event.target.value);
        this.setState({
            treatments: this.state.treatments
        });
    }
    createInput = (description, key, index)=>{
        return <input className="pInput" type='text'
        //this.getPropertyValue(this.state.treatments[index], key)
        value={ typeof this.getPropertyValue(this.state.treatments[index], key) !== "undefined" ? this.getPropertyValue(this.state.treatments[index], key) : ""}
        onChange={(event)=>this.handleChange(event, key, index)}
        placeholder={description} ></input>;
    }
    createInputNumber = (description, key, index)=>{
        return <input className="pInput" type='number'
        value={typeof  this.getPropertyValue(this.state.treatments[index], key) !== "undefined" ?  this.getPropertyValue(this.state.treatments[index], key) : ""}
        onChange={(event)=>this.handleChange(event, key, index)}
        placeholder={description}></input>;
    }
    createRow = (description,value)=>{
        return <tr><td className="pLeftInput">{description}</td><td className="pRightInput">{value}</td></tr>;
    }
    createTitlesRow = (title1, title2, title3, title4) =>{
        return <tr>
                <td>{title1}</td><td>{title2}</td><td>{title3}</td><td>{title4}</td>
            </tr>
    }
    createTreatmentRow = (treatmentName, startingDate,endingDate, benefits, sName, sDayOfWeek, sDate, sDurationInMinutes)=>{
        return <span>
                <tr>
                    <td>{treatmentName}</td><td>{startingDate}</td><td>{endingDate}</td><td>{benefits}</td>
                </tr>
                <tr>
                    <td>{sName}</td><td>{sDayOfWeek}</td><td>{sDate}</td><td>{sDurationInMinutes}</td>
                </tr>
            </span>;
    }
    createDate = (key, index) =>{
        return <DatePicker selected = {typeof  this.getPropertyValue(this.state.treatments[index], key) !== "undefined"?
        Date.parse( this.getPropertyValue(this.state.treatments[index], key)): new Date()} onChange = {(event)=> this.handleDateChange(event, key, index)}/>;
    }
    createDateTime = (key, index)=>{
        return <DatePicker showTimeSelect showTimeSelectOnly timeCaption="Time" dateFormat="hh:mm aa" timeIntervals={5} selected = {typeof  this.getPropertyValue(this.state.treatments[index], key) !== "undefined"?
        Date.parse( this.getPropertyValue(this.state.treatments[index], key)): new Date()} onChange = {(event)=> this.handleDateChange(event, key, index)}/>;
    }
    createDropdownDay = (key, index)=>{
        return (
            <form>
              <label>
                <select value={this.getPropertyValue(this.state.treatments[index], key)} onChange={(event)=>this.handleDropdownChange(event, key, index)}>
                  <option value="Monday">Δευτέρα</option>
                  <option value="Tuesday">Τρίτη</option>
                  <option value="Wednesday">Τετάρτη</option>
                  <option value="Thursday">Πέμπτη</option>
                  <option value="Friday">Παρασκευή</option>
                  <option value="Saturday">Σάββατο</option>
                  <option value="Sunday">Κυριακή</option>
                </select>
              </label>
            </form>
          );
    }
    createTreatmentTable = (index) =>{
        return <table className='treatmentTable'><tbody>
                <tr><td colSpan='4'><div className="border"></div><h3 style={{fontSize:20, textAlign:"center"}}>Θεραπεία</h3></td></tr>
                <tr>
                    <td>Όνομα</td>
                    <td>Ημερομηνία έναρξης</td>
                    <td>Ημερομηνία λήξης</td>
                    <td>Οφέλη</td>
                </tr>
                <tr>
                    <td>{this.createInput("Όνομα:", "treatmentName", index)}</td>
                    <td>{this.createDate("startingDate", index)}</td>
                    <td>{this.createDate("endingDate", index)}</td>
                    <td>{this.createInput("Οφέλη:", "benefits", index)}</td>
                </tr>
                <tr><td colSpan='4'><h3 style={{fontSize:20, textAlign:"center"}}>Συνεδρία</h3></td></tr>
                <tr>
                    <td>Όνομα</td>
                    <td>Μέρα</td>
                    <td>Ώρα</td>
                    <td>Διάρκεια</td>
                </tr>
                <tr>
                    <td>{this.createInput("Όνομα:", "Session.name", index)}</td>
                    <td>{this.createDropdownDay("Session.dayOfWeek", index)}</td>
                    <td>{this.createDateTime("Session.time", index)}</td>
                    <td>{this.createInputNumber("Διάρκεια:", "Session.durationInMinutes", index)}</td>
                </tr>
                <tr><td colSpan='4'>{this.createDeleteBtn(index)}</td></tr>
                </tbody></table>;
    }
    addRow(){
        var nextState = this.state;
        nextState.treatments.push(new Treatment());
        this.setState(nextState)
    }
    createDeleteBtn(index){
        return <button className="addBtn" style={{margin: "auto", display:"block"}}onClick={()=>{
            var nextState = this.state;
            nextState.treatments.splice(index, 1);
            this.setState(nextState)
            }}>
            <img className="imgBtn" src={require('../images/delete.png')}></img><p className="txtBtn">ΔΙΑΓΡΑΦΗ</p></button>
    }

    treatmentSaveBtn(){
        if (this.props.patient == null)
            return;
        this.state.treatments.user_id = this.props.patient._id;
        this.props.patient.treatments = this.state.treatments;
        axios.put(url+"/treatment",{
            user_id: this.props.patient._id,
            userId: this.props.patient.userId,
            treatments: this.state.treatments
        },{headers: {"Authorization": localStorage.getItem("token")}})
            .then(function (treatment) {
                console.warn(treatment);
            }).catch(err=>{
                console.warn(err);
            });
    }

    render(){
        return(
            <div className="flex-container">
                <div className="pageHead">
                    <img className="notesImage" src={require('../images/notes_01.png')}></img>
                    <h3>Θεραπείες και Συνεδρίες</h3>
                </div>
                <table className="medTable" style={{textAlign: "center"}}>
                    <tbody>
                        <tr>
                            <td colSpan="4" className="center">
                                <button className="addBtn" onClick={()=>this.addRow()}>
                                    <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img><p className="txtBtn">ΠΡΟΣΘΗΚΗ</p>
                                </button>
                            </td>
                        </tr>
                        {this.state.treatments.map((treatment, index) => this.createTreatmentTable(index),this)}
                    </tbody>
                </table>
                <button className="addBtn" style={{fontSize:20, fontWeight:"bold", textAlign:"center"}} onClick={()=>{
                    this.treatmentSaveBtn();
                    }}>
                    ΑΠΟΘΗΚΕΥΣΗ
                </button>
            </div>
        );
    }
}
export default PatientTreatment;