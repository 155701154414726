

export default class DietProfile{
    constructor(mealDesc = "", date = new Date()) {
        this.mealDesc = mealDesc;
        this.date = date;
    }

    
}


