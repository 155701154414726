import React from 'react';
import axios from 'axios';
import PatientProfile from '../models/PatientProfile';
// CSS Imports
import '../css/PatientSleep.css';
import '../css/PatientNote.css';
import 'react-dropdown/style.css';
import '../css/Tab.css';
import MinutesWalkingMeasurement from '../models/MinutesWalkingMeasurement';
import StepsMeasurement from '../models/StepsMeasurement';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import SleepQualityAssement from '../models/SleepQualityAssessment';

class PatientCharts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: new PatientProfile(),
            minutesWalkingMeasurement: [new MinutesWalkingMeasurement()],
            stepsMeasurement: [new StepsMeasurement()],
            sleepQualityAssessment: [new SleepQualityAssement()]
        }
        this.getPatient = "https://hm-api-v2.entranet.us:7656/main/patient";
    }

    getChartsData(){
        axios.post(this.getPatient + "/" + this.props.patient.userId, {},
                {headers: {"Authorization": localStorage.getItem("token")}}
        ).then(res=> {
            if (typeof res.data !== "undefined"){
                let minutesWalking = [];
                let minutesWalkingMeasurement = res.data.data.minutesWalkingMeasurement || [];
                let steps = [];
                let stepsMeasurement = res.data.data.stepsMeasurement || [];
                let sleepQuality = [];
                let sleepQualityAssessment = res.data.data.sleepQualityAssessment || [];
                for (let i=0;i<minutesWalkingMeasurement.length;i++){
                    minutesWalking.push(new MinutesWalkingMeasurement(minutesWalkingMeasurement[i].Day, minutesWalkingMeasurement[i].MinutesWalking));
                }
                for (let i=0;i<stepsMeasurement.length;i++){
                    steps.push(new StepsMeasurement(stepsMeasurement[i].Day, stepsMeasurement[i].Steps));
                }
                for (let i=0;i<sleepQualityAssessment.length;i++){
                    sleepQuality.push(new SleepQualityAssement(sleepQualityAssessment[i].timestamp, sleepQualityAssessment[i].Alert, sleepQualityAssessment[i].SE, sleepQualityAssessment[i].sleepDuration, sleepQualityAssessment[i].numberOfInterrupts, sleepQualityAssessment[i].sensor_id));
                }

                this.setState({
                    minutesWalkingMeasurement: minutesWalking,
                    stepsMeasurement: steps,
                    sleepQualityAssessment: sleepQuality,
                });
            }
        }).catch(err=>{
            console.log(err);
        });
    }

    // -- Component Methods --
    componentDidUpdate(prevProps) {
        if (prevProps.patient !== this.props.patient) {
            if(this.props.patient!=null){
                this.setState({
                    patient: this.props.patient,
                });
                this.getChartsData();
            }
        }
    }

    componentDidMount() {
        if(this.props.patient!=null){
            this.state.patient = this.props.patient;
            this.setState({
                patient: this.state.patient,
            });
            this.getChartsData();
        }
    }

    componentWillUnmount() {

    }
    // -- Component Methods --

    // -- Handle Methods --
    handleTimeChange(date){
        this.state.timestamp = date;
        this.setState({
            timestamp: this.state.timestamp
        })
    }
    handleChange(event, key){
        this.state[key] = event.target.value;
        this.setState({
            [key]: this.state[key]
        });
        console.warn(this.state[key]);
    }
    // -- Handle Methods --

    // get measurements for last month only
    getMonthMeasurements(array){
        if (typeof array == "undefined") return;
        let today = new Date();
        let newArray;
        let i = 0;
        array.forEach(element => {
            if (element == null) return null;
            if (element.Day == null) return null;
            let differenceInDays = (today.getTime() - new Date(element.Day).getTime()) / (1000 * 3600 * 24);
            if (differenceInDays < 30){
                newArray[i] = element;
                i++;
            }
        });
        return newArray;
    }
    createTable(index){
        var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
        return  <div className="myBackground">
                    <p>
                        <b style={{fontSize:18}}>Timestamp:&nbsp;&nbsp;</b>
                        {new Date(this.state.sleepQualityAssessment[index].timestamp).toLocaleDateString("el-GR", options)}
                    </p>
                    <p>
                        <b style={{fontSize:18}}>Alert:&nbsp;&nbsp;</b>
                        {String(this.state.sleepQualityAssessment[index].Alert)}
                    </p>
                    <p>
                        <b style={{fontSize:18}}>SE:&nbsp;&nbsp;</b>
                        {this.state.sleepQualityAssessment[index].SE}
                    </p>
                    <p>
                        <b style={{fontSize:18}}>Sleep Duration:&nbsp;&nbsp;</b>
                        {this.state.sleepQualityAssessment[index].sleepDuration}
                    </p>
                    <p>
                        <b style={{fontSize:18}}>Number of Interrupts:&nbsp;&nbsp;</b>
                        {this.state.sleepQualityAssessment[index].numberOfInterrupts}
                    </p>
                    <p>
                        <b style={{fontSize:18}}>Sensor ID:&nbsp;&nbsp;</b>
                        {this.state.sleepQualityAssessment[index].sensor_id}
                    </p>
                    <hr className="solid"/>
            </div>;
    }

    createWalkingTable(key, key2, index){
        var text = (key2 == "Steps") ? "Διάρκεια βάδισης σε λεπτά:" : "Βήματα:";
        var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
        return  <div className="myBackground">
                    <p>
                        <b style={{fontSize:18}}>Μέρα:&nbsp;&nbsp;</b>
                        {new Date(this.state[key][index].Day).toLocaleDateString("el-GR", options)}<br></br>
                        <b style={{fontSize:18}}>{text}&nbsp;&nbsp;</b>
                        {this.state[key][index][key2]}
                    </p>
            </div>;
    }
    createChartSleep(){
        let sleep = this.state.sleepQualityAssessment;
        sleep.forEach(entry => {
            if (typeof entry != "undefined"){
                entry.timestamp = new Date(entry.timestamp).toLocaleDateString("el-GR");
            }
        });
        return [<LineChart width={600} height={180} data={sleep} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Line type="monotone" dataKey="SE" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="timestamp" />
            <YAxis />
            <Tooltip />
            </LineChart>,
            <LineChart width={600} height={180} data={sleep} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="sleepDuration" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
            </LineChart>,
            <LineChart width={600} height={180} data={sleep} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="numberOfInterrupts" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
            </LineChart>];
    }

    createChartWalking(){
        return [<LineChart key="steps" width={400} height={200} data={this.state.stepsMeasurement} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Line type="monotone" dataKey="Steps" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="Day" />
            <YAxis />
            <Tooltip/>
        </LineChart>,
        <LineChart key="minutesWalking" width={400} height={200} data={this.state.minutesWalkingMeasurement} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Line type="monotone" dataKey="MinutesWalking" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="Day" />
            <YAxis />
            <Tooltip />
        </LineChart>
        ];
    }

    render() {
        return (
            <div className="flex-container">
                <div className="pageHead">
                    <img className="sleepImage" src={require('../images/bed.png')}></img>
                    <h3>Γραφήματα</h3>
                </div>
                <h3 className="pageHead">Γραφήματα Ποιότητας Ύπνου</h3>
                <div className="pageHead">
                    {this.createChartSleep()}
                </div>
                <h3 className="pageHead">Γραφήματα Βάδισης</h3>
                <div className="pageHead sideBySide">
                    {this.createChartWalking()}
                </div>
                {/* <div className="sideBySide pageHead center">
                    <table className="center fixed">
                        <tbody>
                            <tr>
                                <td>
                                    <h3 className="pageHead">Αξιολόγηση Ποιότητας Ύπνου</h3>
                                </td>
                            </tr>
                        <tr>
                            <td>
                                {this.state.patient.sleepQualityAssessment.map((assess, index) => this.createTable(index))}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table className="center fixed">
                        <tbody>
                        <tr>
                            <td>
                                <h3 className="pageHead">Μετρήσεις βάδισης</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {this.state.stepsMeasurement.map((measurement, index) => this.createWalkingTable("stepsMeasurement", "Steps", index))}
                                {this.state.minutesWalkingMeasurement.map((measurement, index) => this.createWalkingTable("minutesWalkingMeasurement", "MinutesWalking", index))}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div> */}
            </div>
        );
    }
}

export default PatientCharts;