import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
//import Home from './Stateless/Home';
//import { messaging } from './firebase/firebase';
import "react-datepicker/dist/react-datepicker.css";

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function (registration) {
            //messaging.useServiceWorker(registration);
            //console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
            console.log("Service worker registration failed, error:", err);
        });
}

if (window.innerWidth > 600) {
    // ReactDOM.render(<App />, document.getElementById('root'));
    ReactDOM.render(<App />, document.getElementById('root'));
    registerServiceWorker();
}
else {
    window.location.assign('https://m.housemate.online');
}