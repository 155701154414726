import React from 'react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import url from './PatientPath';
import PatientProfile from '../models/PatientProfile';
import NumberFormat from 'react-number-format';
// CSS imports
import '../css/PatientNote.css';
import '../css/PatientReceived.css';
import '../css/Tab.css';
import 'react-dropdown/style.css';

class PatientReceived extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: new PatientProfile(),
            receivedMeals: [],
            receivedDrinks: [],
            receivedMedication: [],
            receivedPainkiller: [],
            timestamp: new Date(Date.now()),
            personnelId: this.props.loggedInUser || "",
            thirdField: "",
            whereToAdd: "receivedMeals"
        }
    }

    // -- Component Methods --
    componentDidUpdate(prevProps) {
        if (prevProps.patient !== this.props.patient) {
            if(this.props.patient!=null){
            this.setState({
                patient: this.props.patient
            });
        }
        }
    }


    componentDidMount() {
        if(this.props.patient!==null){
            this.state.patient = this.props.patient;
            this.setState({
                patient: this.state.patient
            });
            if (typeof this.state.patient.receivedMeals !== 'undefined' && typeof this.state.patient.receivedDrinks !== 'undefined' &&
                typeof this.state.patient.receivedMedication !== 'undefined' && typeof this.state.patient.receivedPainkiller !== 'undefined'){
                    this.setState({
                        receivedMeals: this.state.patient.receivedMeals,
                        receivedDrinks: this.state.patient.receivedDrinks,
                        receivedMedication: this.state.patient.receivedMedication,
                        receivedPainkiller: this.state.patient.receivedPainkiller
                    });
            }
        }
    }

    componentWillUnmount() {

    }
    // -- Component Methods --

    // -- Handle Methods --
    handleTimeChange(date){
        this.state.timestamp = date;
        this.setState({
            timestamp: this.state.timestamp
        })
    }
    handleChange(event, key){
        this.state[key] = event.target.value;
        this.setState({
            [key]: this.state[key]
        });
        console.warn(this.state[key]);
    }
    // -- Handle Methods --

    createTable(whereToAdd, thirdField, index){
        var options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
        return <p>
                    &nbsp;-&nbsp;&nbsp;
                    {/* new Date is used because Date format is different here and from mondo db */}
                    {new Date(this.state[whereToAdd][index].timestamp).toLocaleDateString("el-GR", options)}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.state[whereToAdd][index].personnelId}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.state[whereToAdd][index][thirdField]}
                    &nbsp;&nbsp;
                    {this.createDeleteBtn(whereToAdd, index)}
                </p>;
    }

    createDeleteBtn(whereToAdd, index){
        return <button className="iconOnlyBtn" style={{marginTop:0}} onClick={()=>{
            var nextState = this.state;
            nextState[whereToAdd].splice(index, 1);
            this.setState(nextState);
            }}>
            <img className="imgBtn" src={require('../images/delete.png')}></img></button>
    }
    addRow(whereToAdd){
        if (this.state.thirdField == ""){
            return alert("ΣΥΜΠΛΗΡΩΣΤΕ ΤΟ ΠΕΔΙΟ, ΔΕΝ ΕΠΙΤΡΕΠΕΤΑΙ ΝΑ ΕΙΝΑΙ ΚΕΝΟ");
        }
        let thirdField;
        switch(whereToAdd){
            case "receivedMeals":
                thirdField = "eatingAmount";
                break;
            case "receivedDrinks":
                thirdField = "receivedDrinkingAmount";
                break;
            case "receivedMedication":
                thirdField = "medicationName";
                break;
            case "receivedPainkiller":
                thirdField = "painkillerName";
                break;
        }

        var nextState = this.state;
        console.warn(whereToAdd);
        let newEntry = {timestamp: this.state.timestamp, personnelId: this.state.personnelId, [thirdField]: this.state.thirdField};
        console.warn(newEntry);
        nextState[whereToAdd].push(newEntry);
        this.setState({
            [whereToAdd]: nextState[whereToAdd]
        });
        console.warn(this.state[whereToAdd]);
    }

    createTimeStamp(){
        return <DatePicker
                value={this.state.timestamp}
                selected={this.state.timestamp}
                onChange={(date)=> this.handleTimeChange(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="d MMMM yyyy h:mm aa"
            />;
    }
    createInput(description, key){
        if (key == "personnelId"){
            return <input className="pInput" type='text'
                    disabled='true'
                    value={this.state[key]}
                    onChange={(event)=>this.handleChange(event, key)}
                    >
                </input>;
        } else{
            if (description == "receivedMedication" || description == "receivedPainkiller"){
                const listItems = this.state.patient.medication.map((d) => <option value={d.medicationName}>{d.medicationName}</option>);
                return <form>
                    <label>
                    <select value={this.state[key]} onChange={(event)=>this.handleChange(event, key)}>
                        <option value={""}></option>
                        {listItems}
                    </select>
                    </label>
                </form>;
                /*return <input className="pInput" type='text'
                    value={this.state[key]}
                    onChange={(event)=>this.handleChange(event, key)}
                    placeholder={description}
                    >
                </input>;*/
            } else{
                return <NumberFormat
                    value={this.state[key]}
                    onChange={(event)=>this.handleChange(event, key)}
                    placeholder={description}
                    >
                </NumberFormat>;
            }
        }
    }
    createDropdown = (key)=>{
        return <form>
                <label>
                <select value={this.state.patient[key]} onChange={(event)=>this.handleChange(event, key)}>
                    <option value="receivedMeals">Γεύματα σε %</option>
                    <option value="receivedDrinks">Πόσιμα σε mL</option>
                    <option value="receivedMedication">Φάρμακα</option>
                    <option value="receivedPainkiller">Αναλγητικά</option>

                </select>
                </label>
            </form>;
    }

    receivedSaveBtn(){
        this.state.patient.receivedMeals = this.state.receivedMeals;
        this.state.patient.receivedDrinks = this.state.receivedDrinks;
        this.state.patient.receivedMedication = this.state.receivedMedication;
        this.state.patient.receivedPainkiller = this.state.receivedPainkiller;
        this.setState({
            patient: this.state.patient
        });
        axios.put(url+"/patient",
            this.state.patient,{headers: {"Authorization": localStorage.getItem("token")}})
            .then(function (patient) {
                console.log(patient);
            }).catch(err=>{
                console.log(err);
            });
    }

    render() {
        return (
            <div className="flex-container">
                <div className="pageHead">
                    <img className="dietImage" src={require('../images/diet_doctor.png')}></img>
                    <h3>Λήψη Γευμάτων και Φαρμακευτικής Αγωγής</h3>
                </div>
                <table className="receivedTable">
                    <tbody>
                        <tr>
                            <td colSpan="3"></td>
                            <td className="center">
                                {/* Save Button */}
                                <button className="addBtn" style={{fontSize:20, fontWeight:"bold", textAlign:"center"}}
                                            onClick={()=>{
                                                this.receivedSaveBtn();
                                            }}>ΑΠΟΘΗΚΕΥΣΗ
                                </button>
                            </td>
                        </tr>
                    <tr>
                        <td></td>
                        <td>
                            {/* Timestamp Input */}
                            {this.createTimeStamp()}
                        </td>
                        <td>
                            {/* Personnel ID Input */}
                            {this.createInput("Κωδικός Προσωπικού", "personnelId")}
                        </td>
                        <td>
                            {/* Text Input*/}
                            {this.createInput(this.state.whereToAdd, "thirdField")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                        <td className="center">
                            {/* Dropdown */}
                            {this.createDropdown("whereToAdd")}
                        </td>
                        <td className="center">
                            {/* Add button */}
                            <button className="addBtn" onClick={()=>this.addRow(this.state.whereToAdd)}>
                                <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img><p className="txtBtn">ΠΡΟΣΘΗΚΗ</p>
                            </button>
                        </td>
                    </tr>

                    <tr>
                        <th colSpan="4" style={{fontSize:20}}>Γεύματα</th>
                    </tr>
                    <tr className="myBackground">
                        <td colSpan="4">
                            {this.state.receivedMeals.map((meal, index) => this.createTable("receivedMeals", "eatingAmount", index))}
                        </td>
                    </tr>
                    {/* Seperator */}
                    <tr><td style={{paddingBottom: 0}} colSpan="4"><hr className="solid"/></td></tr>
                    <tr>
                        <th colSpan="4" style={{fontSize:20}}>Πόσιμα</th>
                    </tr>
                    <tr className="myBackground">
                        <td colSpan="4">
                            {this.state.receivedDrinks.map((drink, index) => this.createTable("receivedDrinks", "receivedDrinkingAmount", index))}
                        </td>
                    </tr>
                    {/* Seperator */}
                    <tr><td style={{paddingBottom: 0}} colSpan="4"><hr className="solid"/></td></tr>
                    <tr>
                        <th colSpan="4" style={{fontSize:20}}>Φάρμακα</th>
                    </tr>
                    <tr className="myBackground">
                        <td colSpan="4">
                            {this.state.receivedMedication.map((medication, index) => this.createTable("receivedMedication", "medicationName", index))}
                        </td>
                    </tr>
                    {/* Seperator */}
                    <tr><td style={{paddingBottom: 0}} colSpan="4"><hr className="solid"/></td></tr>
                    <tr>
                        <th colSpan="4" style={{fontSize:20}}>Αναλγητικά</th>
                    </tr>
                    <tr className="myBackground">
                        <td colSpan="4">
                            {this.state.receivedPainkiller.map((painkiller, index) => this.createTable("receivedPainkiller", "painkillerName", index))}
                        </td>
                    </tr>
                    </tbody>
                </table>
        </div>
        );
    }
}

export default PatientReceived;