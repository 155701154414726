import React from 'react';
import dateformat from 'dateformat';
// CSS imports
import '../css/PatientNote.css';
import '../css/Tab.css';



class PatientMedicine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            medicineArray : [
                {

                    date: '01.01.2020',
                    time: '07.30',
                    name: 'IVOR Suring 10x3500iu',
                    unit: 'Caps',
                    quantity: '2',
                    consumption: '2',
                    providing: 'Υποδόρια',
                    duration: '2 ώρες',
                    ending: '09:30'
                },
                {
                    date: '01.01.2020',
                    time: '10.20',
                    name: 'GALVUS TAB 28x50mg/TAB',
                    unit: 'TAB',
                    quantity: '1',
                    consumption: '1',
                    providing: 'στόμα',
                    duration: '2 ώρες',
                    ending: '12:20'
                },
                {
                    date: '01.01.2020',
                    time: '17.40',
                    name: 'PIEITON F.C. TAB 300mg/TAB',
                    unit: 'TAB',
                    quantity: '0,50',
                    consumption: '0,50',
                    providing: 'στόμα',
                    duration: '2 ώρες',
                    ending: '18:40'
                }
            ]
        };
    }


    componentDidMount() {
        this.setState({
            date: new Date()
        });
    }

    componentWillUnmount() {
    }


    render() {
        return ( <div className="flex-container">
        <div>
            <img className="medImage" src={require('../images/medicines_doctor.png')}></img>
            <h3>φαρμακευτική αγωγή</h3>
        </div>
        <table table className="medTable" style = {{textAlign: "left"}}>
            <thead>
                <tr>
                    <th colSpan='2' style={{fontSize:20, paddingBottom:25}}>ΗΜΕΡΗΣΙΟ ΠΡΟΓΡΑΜΜΑ</th>
                </tr>
            </thead>
            <tbody>
                {this.state.medicineArray.map((entry,index)=><tr className="medicineInfo"><td>{entry.date}</td>
                <td className="medTime">{entry.time}</td><td>{entry.name}</td><td className="medQuantity">{entry.quantity}</td><td>{entry.providing}</td></tr>)}   
            </tbody>
        </table>
            </div>
        );
    }
}

export default PatientMedicine;
