import React, { Component } from 'react';
import axios from 'axios';
import md5 from 'md5';
import Camera from './Components/Camera'
import Switches from './Components/Switches'
import Thermostat from './Components/Thermostat'
import PatientProfile from './models/PatientProfile.js';
import { RingLoader } from 'react-spinners';
import { BrowserRouter, Route } from 'react-router-dom';
//import { messaging } from './firebase/firebase';
import firebase from 'firebase/app'
import 'firebase/messaging'
import url from './Components/PatientPath';
import {fetchThermostats} from './ThermostatFunctions';
import {fetchSwitches} from './SwitchFunctions';
import { ToastContainer, toast } from 'react-toastify';

import { getBuildingDevices } from './buildingFunctions';

// UNUSED IMPORTS
// import Clock from './Components/Clock';
// import Weather from './Components/Weather'
// import Settings from './Components/Settings'
// import Scenarios from './Components/Scenarios'
// import Actions from './Components/Actions'

// PATIENT TABS
import PatientInfo from './Components/PatientInfo'
import PatientNote from './Components/PatientNote'
import PatientMedicine from './Components/PatientMedicine'
import PatientDiet from './Components/PatientDiet'
import PatientBiometric from './Components/PatientBiometric'
import PatientInputInfo from './Components/PatientInputInfo'
import PatientInputMedicine from './Components/PatientInputMedicine'
import PatientActivities from './Components/PatientActivities'
//import PatientRehab from './Components/PatientRehab'
import PatientFuncSkills from './Components/PatientFuncSkills'
import PatientReceived from './Components/PatientReceived';
import PatientCharts from './Components/PatientCharts';
import PatientTreatment from './Components/PatientTreatment'
import PatientInputDiet from './Components/PatientInputDiet'
//import PatientGuests from './Components/PatientGuests'

// CSS
import './AppRea.css';
import 'react-toastify/dist/ReactToastify.css';

firebase.initializeApp({
  apiKey: "AIzaSyABrmdwvI_deXAkBjxiFkD0ZgFySt0F1W8",
  authDomain: "rea-nurse.firebaseapp.com",
  databaseURL: "https://rea-nurse.firebaseio.com",
  projectId: "rea-nurse",
  storageBucket: "rea-nurse.appspot.com",
  messagingSenderId: "481449809875",
  appId: "1:481449809875:web:30db04a21b35aa02eb8126"
});
const messaging = firebase.messaging();

const initialState = {
  loading: false,
  selected: 0,
  onBuildingName: false,
  loginName: "",
  loginEmail: "",
  loginPassword: "",
  email: "",
  failedLogin: false,
  token: "",
  buildingID: "",
  buildings: [{ name: "" }],
  selectedBuilding: {
    actions: [],
    buildingID: "",
    devices: [],
    isMaster: false,
    name: "",
    settings: {
      numberOfNews: 0
    },
    permissions: {
      canAccessCamera: false,
      canAccessSwitches: false,
      canModifyActions: false,
      canModifyScenes: false,
      canReceiveEmergencyNotification: false
    },
    scenes: [],
    switches: [],
    thermostats: [],
  },
  switches: [],
  thermostats: [],
  devices: [],
  calling: [],
  notes: false,
  camera: false,
  cards: false,
  doctor: [],
  insideRoom: false,
  help: [],
  note: '',
  notesarea: '',
  months: ["Ιαν", "Φεβ", "Μαρ", "Απρ", "Μαϊ", "Ιουν", "Ιουλ", "Αυγ", "Σεπ", "Οκτ", "Νοε", "Δεκ"],
  days: ["Κυριακή", "Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο"],
  //doctorCardImages: ['kartela_profil', 'kartela_biometrika', 'kartela_diatrofi', 'kartela_farmaka', 'kartela_ergastiriaka', 'kartela_simeiwmta'],
  doctorCardImages: [/*PatientInfo, PatientNote, PatientMedicine, PatientDiet,*/ PatientInputInfo, PatientInputMedicine, PatientInputDiet,  PatientBiometric, PatientActivities, PatientTreatment, PatientFuncSkills, PatientReceived, PatientCharts/*, PatientGuests*/],
  doctorCardImage: 0,
  patients:[],
  patientIndex:0,
  patient: null,
};

const hmApiV1 = "https://hm-api.entranet.us:7656/";
const hmApiV2 = "https://hm-api-v2.entranet.us:7656/";


class App extends Component {

  constructor(props) {
    super(props);

    this.state = initialState;

    // BINDINGS
    this.handleChange = this.handleChange.bind(this);
    this.clickedLogin = this.clickedLogin.bind(this);
    this.clickedLogout = this.clickedLogout.bind(this);
    this.selectedLogin = this.selectedLogin.bind(this);
    this.clickedRegister = this.clickedRegister.bind(this);
    this.selectedAllBuildings = this.selectedAllBuildings.bind(this);
    this.clickedRegisterDoctor = this.clickedRegisterDoctor.bind(this);
    this.selectedRegister = this.selectedRegister.bind(this);
    this.selectedRegisterDoctor = this.selectedRegisterDoctor.bind(this);
    this.clickedAddBuilding = this.clickedAddBuilding.bind(this);
    this.selectBuilding = this.selectBuilding.bind(this);
    this.initiate = this.initiate.bind(this);
    this.updateState = this.updateState.bind(this);

    // API ENDPOINTS
    this.loginLink = hmApiV2 + "main/nurse/login";
    this.registerLink = hmApiV1 + "main/nurse";
    this.registerLinkDoctor = hmApiV1 + "main/doctor";
    this.getBuildingsLink = hmApiV1 + "main/building/getBuildingsForUser";
    this.addBuildingLink = hmApiV1 + "main/building/addUserToBuilding";
    this.changeTokenNurse = hmApiV1 + "main/nurse/token";

    this.textInput = null;
    this.setTextInputRef = element => {
      this.textInput = element;
      if (element)
        element.focus();
    };
    /*this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      if (this.state.notes && this.textInput) {
        this.textInput.focus();
      }
    };*/
  }
  // Resets-Initializes state's variables
  reset(){
    this.setState(initialState);
  }

  // -- COMPONENT METHODS --
  componentWillMount() {
    this.initiate();
  }
  componentDidMount() {
    if (Notification) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          //console.log('Notification permission granted.');
          // Get Instance ID token. Initially this makes a network call, once retrieved
          // subsequent calls to getToken will return from cache.
          messaging.getToken().then((currentToken) => {
            if (currentToken) {
              //console.log('Instance ID token available.');
              //sendTokenToServer(currentToken);
              axios
                .post(this.changeTokenNurse, { email: this.state.loginEmail, token: currentToken })
                .then(function (response) {

                }).catch(err => {
                  console.log('token not saved');
                });
            } else {
              // Show permission request.
              console.log('No Instance ID token available. Request permission to generate one.');
              // Show permission UI.
              //updateUIForPushPermissionRequired();
              //setTokenSentToServer(false);
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            //showToken('Error retrieving Instance ID token. ', err);
            //setTokenSentToServer(false);
          });
          // Callback fired if Instance ID token is updated.
          messaging.onTokenRefresh(() => {
            messaging.getToken().then((refreshedToken) => {
              console.log('Token refreshed.');
              //setTokenSentToServer(false);
              // Send Instance ID token to app server.
              //sendTokenToServer(refreshedToken);
            }).catch((err) => {
              console.log('Unable to retrieve refreshed token ', err);
            });
          });
          messaging.onMessage((payload) => {
            //console.log("Payload ");
            //console.log(payload);
            //registration.showNotification("Hare re eres");
          });
        } else {
          console.log('Unable to get permission to notify.');
        }
      });
    }
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (message) => {
        let notificationData;
        if (message.data && message.data["firebase-messaging-msg-data"] && message.data["firebase-messaging-msg-data"].data)
          notificationData = message.data["firebase-messaging-msg-data"].data;
        else notificationData = message.data.data;
        const notificationType = notificationData.type;
        const notificationFrom = notificationData.person;
        console.log("notif");
        console.log(notificationData);
        if (this.state.email.length > 0) {
          //user is logged in

          if (notificationType == 'alert'){
            console.warn("Fall Alert");
            let alert = JSON.parse(notificationData.building);
            console.warn(alert); // {userId: userId, fallAlert: fallAlert, timestamp: timestamp}
            // find userId in patients
            let userIndex = -1;
            for (let i=0;i<this.state.patients.length;i++){
              if (this.state.patients[i].userId == alert.userId){
                  userIndex = i;
                  break;
              }
            }
            if (userIndex !== -1){
              let objectId = this.state.patients[userIndex]._id;
              let patientName = this.state.patients[userIndex].name;
              axios.get(url+"/user/id/"+objectId,
                        {headers: {"Authorization": localStorage.getItem("token")}}
                ).then(res=> {
                  console.log(res.data);
                  let room = res.data.room;
                  let building = this.state.buildings.find(function (building) {
                    return building.name === room;
                  })
                  let timestamp = new Date(alert.timestamp); //Converts UTC timestamp to local time

                  let toastText = 'Ένας ασθενής (' + patientName + ') έπεσε στο δωμάτιο ' + room + ' στις ' + timestamp;
                  // Toast notification
                  toast.warn(toastText, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });

                  // colorize building to show alert
                  this.state.calling.push(building.buildingID);
                  this.state.doctor.push(building.buildingID);
                  this.setState({
                    calling: this.state.calling,
                    doctor: this.state.doctor
                  })
                  // remove alert after 15s
                  setTimeout(() => this.remove(building.buildingID), 15000);


                }).catch(err=>{
                  console.log(err);
                });
            } else {
              console.warn("userId not found in patients");
            }
          } else{
            let room = notificationData.building;
            if (this.state.selected === 5 || this.state.selected === 1) {
              //user is in buildings View
              let building = this.state.buildings.find(function (building) {
                return building.buildingID === room;
              })
              if (typeof building !== 'undefined') {
                //found building from notification
                if (notificationFrom === "doctor") {
                  if (notificationType === "call" && this.state.calling.indexOf(building.buildingID) < 0) {
                    this.state.calling.push(building.buildingID);
                    this.state.doctor.push(building.buildingID);
                    this.setState({
                      calling: this.state.calling,
                      doctor: this.state.doctor
                    })
                  } else if (notificationType === "calloff") {
                    this.remove(building.buildingID);
                  }
                } else if (notificationFrom === "help") {
                  if (notificationType === "call" && this.state.calling.indexOf(building.buildingID) < 0) {
                    this.state.calling.push(building.buildingID);
                    this.state.help.push(building.buildingID);
                    this.setState({
                      calling: this.state.calling,
                      help: this.state.help
                    })
                  } else if (notificationType === "calloff") {
                    this.remove(building.buildingID);
                  }
                } else {
                  if (notificationType === "call" && this.state.calling.indexOf(building.buildingID) < 0) {
                    this.state.calling.push(building.buildingID);
                    this.setState({
                      calling: this.state.calling
                    })
                  } else if (notificationType === "calloff") {
                    this.remove(building.buildingID);
                  }
                }
              }
            } else {
              console.log("Outside buildings");
            }
          }
        }
      });
    }

    let token = localStorage.getItem('hmToken');
    let email = localStorage.getItem('email');
    let name = localStorage.getItem('name');
    let buildingID = localStorage.getItem('buildingID');
    if (token !== null && email !== null && buildingID !== null && name != null) {
      this.setState({
        token: token,
        loginEmail: email,
        loginName: name,
        email: email,
        buildingID: buildingID,
      }, () => this.initiate())
    }
    this.getPatients();
  }
  // -- COMPONENT METHODS --


  // -- METHODS USING AXIOS --
  getPatients(){
    axios.get(url+"/patient",
              {headers: {"Authorization": localStorage.getItem("token")}}
      ).then(res=> {
        console.log(res.data);
        if(res.data.length>0){
          this.setState({patient:res.data[0],patients:res.data});
        }
      }).catch(err=>{
        console.log(err);
      });
  }


  loadRoomNotes(buildingId){
    if (typeof buildingId !== 'undefined'){
      axios.get(url+"/note/"+buildingId,
                {headers: {"Authorization": localStorage.getItem("token")}}
      ).then(res=> {
        if(res.data){
          this.state.notesarea = res.data.text;
        } else {
          this.state.notesarea = "";
        }
      }).catch(err=>{
        console.log(err);
      });
    }

    this.setState({
      notesarea: this.state.notesarea
    });
  }

  setPatient(room){
    if(typeof room !== "undefined" && room !== null){
      console.log(room);
      axios.get(url+"/user/"+room,
                {headers: {"Authorization": localStorage.getItem("token")}}
      ).then(res=> {
            let us = res.data;
            for(let i=0;i<this.state.patients.length;i++){
              if(this.state.patients[i]._id==us.user){
                this.setState({patient:this.state.patients[i]});
                break;
              }
            }
          }).catch(err=>{
            console.log(err);
          });
    }
  }

  saveNotes(newNote){
    axios.put(url+"/note/",{
        buildingId: this.state.selectedBuilding.name,
        text: newNote
    },{headers: {"Authorization": localStorage.getItem("token")}})
        .then(function (note) {
            console.warn(note);
        }).catch(err=>{
            console.warn(err);
        });
  }

  // LOGIN
  clickedLogin() {
    this.setState({ failedLogin: false });
    if (!this.state.loginEmail || !this.state.loginPassword) {
      this.setState({ failedLogin: true });
    } else {
      axios.post(this.loginLink, {
        email: this.state.loginEmail,
        password: md5(this.state.loginPassword),
        origin: 2
      })
        .then(function (response) {
          console.log(response);
          if (response.data.state === 0) {
            localStorage.setItem("token", response.data.data.signedToken);
            localStorage.setItem('hmToken', response.data.data.token);
            localStorage.setItem('email', this.state.loginEmail);
            localStorage.setItem('name', response.data.data.name);
            if (response.data.data.buildings.length > 0) {
              localStorage.setItem('buildingID', response.data.data.buildings[0]);
              this.setState({
                token: response.data.data.token, email: this.state.loginEmail,
                buildingID: response.data.data.buildings[0], loginName: response.data.data.name,
                selected: 1 }, () => this.initiate());
              this.setPatient(response.data.data.buildings[0].name);
              this.getPatients();
            }else {
              this.setState({ token: response.data.data.signedToken, email: this.state.loginEmail, selected: 5 });
            }
          } else {
            this.setState({ failedLogin: true, loginPassword: "" });
          }
        }.bind(this));
    }
  }
  // NURSE REGISTER
  clickedRegister() {
    this.setState({ failedLogin: false });
    if (!this.state.registerEmail || !this.state.registerPassword || !this.state.registerRepeatPassword) {
      this.setState({ failedLogin: true });
    } else {
      axios.post(this.registerLink, {
        name: this.state.registerName,
        username: this.state.registerEmail,
        email: this.state.email,
        password: md5(this.state.registerPassword),
        origin: 2
      })
        .then(function (response) {
          if (response.data.state === 0) {
            this.setState({ selected: 5 });
            // Toast notification
            let toastText = 'ΕΓΓΡΑΦΗ ΕΠΙΤΥΧΗΣ. ΚΑΝΤΕ ΑΠΟΣΥΝΔΕΣΗ ΓΙΑ ΝΑ ΣΥΝΔΕΘΕΙΤΕ ΜΕ ΤΟΝ ΝΕΟ ΧΡΗΣΤΗ'
            toast.warn(toastText, {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          } else {
            this.setState({ failedLogin: true });
          }
        }.bind(this));
    }
  }

  // DOCTOR REGISTER
  clickedRegisterDoctor() {
    this.setState({ failedLogin: false });
    if (!this.state.registerEmail || !this.state.registerPassword || !this.state.registerRepeatPassword || this.state.registerPassword.length !== 4) {
      this.setState({ failedLogin: true });
    } else {
      axios.post(this.registerLinkDoctor, {
        name: this.state.registerName,
        username: this.state.registerEmail,
        email: this.state.email,
        pin: md5(this.state.registerPassword),
        origin: 2
      })
        .then(function (response) {
          if (response.data.state === 0) {
            this.setState({ selected: 1 });
            this.setPatient(this.state.selectedBuilding.name);
          } else {
            this.setState({ failedLogin: true });
          }
        }.bind(this));
    }
  }

  // -- METHODS USING AXIOS --


  remove = (building) => {
    let index = this.state.calling.findIndex(room =>
      room === building
    );

    let indexDoctor = this.state.doctor.findIndex(room =>
      room === building
    );

    let indexHelp = this.state.help.findIndex(room =>
      room === building
    );
    if (index > -1) {
      this.state.calling.splice(index, 1);
    }

    if (indexDoctor > -1) {
      this.state.doctor.splice(index, 1);
    }

    if (indexHelp > -1) {
      this.state.help.splice(index, 1);
    }
    this.setState({
      calling: this.state.calling,
      doctor: this.state.doctor,
      help: this.state.help,
    });
  }

  closeCamera = () => {
    this.setState({ camera: false });
  }

  // GET BUILDINGS
  initiate() {
    if ((this.state.selected === 0 || this.state.selected === 3 || this.state.selected === 1) && this.state.token && this.state.buildingID) {
      this.setState({ selected: 5 });
      axios
        .post(this.getBuildingsLink, { token: this.state.token })
        .then(function (response) {
          if (response.data.state === 0) {
            let buildings = response.data.data.buildings;
            if (buildings.length > 0) {
              this.setState({ buildings: buildings, selectedBuilding: buildings.find((building) => building.buildingID === this.state.buildingID) });
            }
          } else {
            console.warn("GET BUILDINGS FAILED")
          }
        }.bind(this)).catch(err => {console.warn(err);});
    }
  }

  getSwitches = () => {
    return fetchSwitches().then((responseData)=> {
      let index = this.state.buildings.findIndex((building) => building.buildingID === this.state.selectedBuilding.buildingID);
              if (index<0) {
                console.log("Building didn't found");
              } else {
                this.state.buildings[index].switches = responseData.data.switches;
              }
      this.state.selectedBuilding.switches = responseData.data.switches;
      this.setState({
        switches: this.state.selectedBuilding.switches
      });
    }).catch((error)=>{
        console.log("Trouble with switches");
    })
  }

  getThermostats = () => {
    return fetchThermostats().then((responseData) =>{
      let index = this.state.buildings.findIndex((building) => building.buildingID === this.state.selectedBuilding.buildingID);
      if(index < 0){
        console.log("Building does not exist");
      }
      else{
        this.state.buildings[index].thermostats = responseData.data.thermostats;
        console.log("Thermostat stored on buildings");
      }
      this.state.selectedBuilding.thermostats = responseData.data.thermostats;
      this.setState({
        thermostats: this.state.selectedBuilding.thermostats
      });
    });
  }

  getDevices = () =>{
    return getBuildingDevices().then((responseData) => {
      console.log("get devices");
      console.log(responseData.data.devices);
      let index = this.state.buildings.findIndex((building) => building.buildingID === this.state.selectedBuilding.buildingID);
      if(index < 0){
        console.log("Building was not found for devices");
      }
      else{
        console.log(responseData.data.devices);
        this.state.buildings[index].devices = responseData.data.devices;
        this.state.selectedBuilding.devices = responseData.data.devices;
        this.setState({
          buildings: this.state.buildings,
          selectedBuilding: this.state.selectedBuilding,
          devices: responseData.data.devices
        });
      }
    });
  }

  selectBuilding(name) {
    let selectedBuilding = this.state.buildings.find(building => building.name === name);
    if(typeof selectedBuilding !== 'undefined'){
      console.log("SelectBuilding: ");
      console.log(this.state.selectedBuilding);
      localStorage.setItem('buildingID', selectedBuilding.buildingID);
      this.setState({ selectedBuilding: selectedBuilding, selected: 1 });
      this.setPatient(selectedBuilding.name);
      this.getSwitches();
      this.getThermostats();
      this.getDevices();
      this.loadRoomNotes(selectedBuilding.name);
    }
  }

  loadNextPatient(){
    if (this.state.patients.length<1) return;
    let index = this.state.patientIndex;
    index++;
    console.log(index);
    if(index >=this.state.patients.length){
      index = 0;
      console.log(index);
    }
    console.log(this.state.patients[index]);
    this.setState({patientIndex:index,patient: this.state.patients[index]});
  }
  loadPreviousPatient(){
    if (this.state.patients.length<1) return;
    let index = this.state.patientIndex;
    index--;
    console.log(index);
    if( index<0){
      index = this.state.patients.length-1;
      console.log(index);
    }
    console.log(this.state.patients[index]);
    this.setState({patientIndex:index, patient: this.state.patients[index]});
  }
  loadNewPatient(){
    console.log("new");
    this.state.patients.push(new PatientProfile());
    let index = this.state.patients.length-1;
    delete this.state.patients[index]._id;
    delete this.state.patients[index].__v;
    this.setState({doctorCardImage:0,patientIndex:index,patient: this.state.patients[index]});
  }



  selectCameraBuilding(name) {
    let selectedBuilding = this.state.buildings.find(building => building.name === name);
    localStorage.setItem('buildingID', selectedBuilding.buildingID);
    this.remove(selectedBuilding.buildingID);
    this.setState({ selectedBuilding: selectedBuilding, selected: 1, camera: true });
    this.setPatient(selectedBuilding.name);
  }

  selectedDoctor = () => {
    if (this.state.doctor.length > 0) {
      let docId = this.state.doctor[0];
      let selectedBuilding = this.state.buildings.find(building => building.buildingID === docId);
      localStorage.setItem('buildingID', selectedBuilding.buildingID);
      this.getDevices().then(() => {
        this.remove(selectedBuilding.buildingID);
        this.setState({ selectedBuilding: selectedBuilding, selected: 1, camera: true });
        this.setPatient(selectedBuilding.name);
      });
    }
  }

  selectedCamera = (name) => {
    let index = this.state.calling.findIndex(room => {
      return room === this.state.selectedBuilding.buildingID;
    });
    if (index > -1) {
      this.remove(this.state.selectedBuilding.buildingID);
    }
    this.setState({ camera: !this.state.camera });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value, failedLogin: false
    });
  }

  clickedLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem('hmToken');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('buildingID');

    this.reset();
    //this.setState({ selected: 0, loginEmail: "", loginPassword: "", cards: false, notes: false, camera: false });
  }

  selectedLogin() {
    this.setState({ selected: 0 });
  }

  selectedAllBuildings() {
    this.setState({ selected: 5, cards: false, notes: false, camera: false, insideRoom:false});
  }

  selectedRegister() {
    if (this.state.email)
      this.setState({ selected: 2, cards: false, notes: false, camera: false });
  }

  selectedRegisterDoctor() {
    if (this.state.email)
      this.setState({ selected: 4, cards: false, notes: false, camera: false });
  }

  addNote = (e) => {
    if (e.key === 'Enter') {
      let newNote = this.state.notesarea + '\n' + this.state.loginEmail + " " + this.getDateFormatForNotes() + ": " + this.state.note;
      this.setState({
        notesarea: newNote,
        note: ''
      });
      this.saveNotes(newNote);
    }
  }


  getDateFormatForNotes = () => {
    var d = new Date();
    var day = this.state.days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }

    var date = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    return day + " " + hr + ":" + min + " " + date + "/" + month + "/" + year;
  }

  addNoteOnChange = (e) => {
    this.setState({
      note: e.target.value
    })
  }

  clickedAddBuilding() {
    this.setState({ failedLogin: false });
    if (!this.state.addBuildingID || !this.state.addBuildingName) {
      this.setState({ failedLogin: true });
    } else {
      axios.post(this.addBuildingLink, {
        buildingID: this.state.addBuildingID,
        token: this.state.token,
        buildingName: this.state.addBuildingName,
        email: this.state.email
      })
        .then(function (response) {
          if (response.data.state === 0) {
            if (!this.state.buildingID) {
              localStorage.setItem('buildingID', this.state.addBuildingID);
              this.setState({ buildingID: this.state.addBuildingID });
            }
            this.setState({ loading: true });
            setTimeout(() => {
              this.initiate();
              this.setState({ loading: false });
            }, 1000);
          } else {
            this.setState({ failedLogin: true });
          }
        }.bind(this));
    }
  }

  /*focus = () => {
    setTimeout(this.focusTextInput, 500);
  }*/

  cardsArrow = (left) => {
    if (left) {
      this.state.doctorCardImage -= 1;
      if (this.state.doctorCardImage < 0) {
        this.state.doctorCardImage = this.state.doctorCardImages.length - 1;
      }
    } else {
      this.state.doctorCardImage += 1;
      if (this.state.doctorCardImage >= this.state.doctorCardImages.length) {
        this.state.doctorCardImage = 0;
      }
    }
    this.setState({ doctorCardImage: this.state.doctorCardImage });
  }

  updateState(obj) {
    this.setState(obj);
  }

  duplicateCheck = (key, value, currentPatient) => {
    for (let i=0;i<this.state.patients.length;i++){
        if (this.state.patients[i]._id == currentPatient){
          continue;
        }
        if (this.state.patients[i][key] == value){
          return true;
        }
    }
    return false;
  }

  updateImage = () => {
    //TODO get patients size and show arrows to change patient
    //pass patient state to components
    if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientInfo) {
      return <PatientInfo />
    } else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientMedicine) {
      return <PatientMedicine />
    } else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientNote) {
      return <PatientNote />
    } else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientDiet) {
      return <PatientDiet />
    } else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientBiometric) {
      return <PatientBiometric patient={this.state.patient} buildingID={this.state.selectedBuilding.buildingID}/>
    } else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientInputInfo) {
      return <PatientInputInfo patient={this.state.patient} room={this.state.selectedBuilding.name} building={this.state.selectedBuilding} duplicateCheck={this.duplicateCheck}/>
    }
    else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientInputMedicine) {
      return <PatientInputMedicine patient={this.state.patient} loggedInUser={typeof this.state.loginName !== 'undefined' ? this.state.loginName : this.state.loginEmail}/>
    }
    else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientInputDiet) {
      return <PatientInputDiet patient={this.state.patient}/>
    }else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientActivities){
      return <PatientActivities patient={this.state.patient}/>
    }else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientTreatment){
      return <PatientTreatment patient={this.state.patient}/>
    }else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientFuncSkills){
      return <PatientFuncSkills patient={this.state.patient} loggedInUser={typeof this.state.loginName !== 'undefined' ? this.state.loginName : this.state.loginEmail}/>
    }else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientReceived){
      return <PatientReceived patient={this.state.patient} loggedInUser={typeof this.state.loginName !== 'undefined' ? this.state.loginName : this.state.loginEmail}/>
    }else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientCharts){
      return <PatientCharts patient={this.state.patient}/>
    }/*else if (this.state.doctorCardImages[this.state.doctorCardImage] === PatientGuests){
      return <PatientGuests patient={this.state.patient} building={this.state.selectedBuilding}/>
    }*/
    /*<img
      id="doctor-card"
      alt="kartela"
      src={require('./images/' + this.state.doctorCardImages[this.state.doctorCardImage] + '.png')}
      style={{
        width: '100%',
      }}>
    </img>;*/
  }

  sortByPropertyNameToIntInc = (a, b) => {
    var parsedA = parseInt(a.name, 10);
    if (isNaN(parsedA)) { return 0 }
    var parsedB = parseInt(b.name, 10);
    if (isNaN(parsedB)) { return 0 }
    if (parsedA > parsedB) {
      return 1;
    }
    if (parsedB > parsedA) {
      return -1;
    }
  }

  centerRow = () => {
    if (this.state.notes === false && this.state.cards === false)
      return( 
      <span className="flexRow">
      {
        typeof this.state.selectedBuilding !== "undefined" && this.state.selectedBuilding.switches.filter((aSwitch) => {
          return aSwitch.type === 'switch';
        }).length > 1 &&
          <span id="menuSwitches" className="flexSW">
            <Switches building={this.state.selectedBuilding} token={this.state.token} buildingID={this.state.buildingID} updateState={this.updateState} />
          </span>}
        
      <span id="menuRight" className="flexCol flexGS">
        <span className="smallWidth flex120 viewBlock flexCol flexAlignCenter"
          onClick={this.selectedDoctor}
          style={{
            backgroundColor: this.state.doctor.length > 0 ? "rgba(0, 0, 255, 0.836)" : ""
          }}>
          <img
            alt=""
            className="myContainerViewImage"
            src={require('./images/doctor.png')}
            style={{
              filter: "brightness(0.4)"
            }}></img>
          <span className="myContainerViewWord">Γιατρός</span>
        </span>
        <span className="smallWidth flex120 viewBlock flexCol flexAlignCenter"
          onClick={() => this.selectedCamera(this.state.selectedBuilding.name)}
          style={{
            backgroundColor: this.state.help.findIndex(room => {
              return room === this.state.selectedBuilding.buildingID;
            }) < 0 ? this.state.doctor.findIndex(room => {
              return room === this.state.selectedBuilding.buildingID;
            }) < 0 ? this.state.calling.findIndex(room => {
              return room === this.state.selectedBuilding.buildingID;
            }) < 0 ? "" : "orange" : "rgba(0, 0, 255, 0.836)" : "red"

          }}>
          <img
            alt=""
            className="myContainerViewImage"
            src={require('./images/camera.png')}
            style={{
              filter: this.state.camera ? "grayscale(100%)" : "hue-rotate(170deg) brightness(0.25)"
            }}></img>
          <span className="myContainerViewWord">Βιντεοκλήση</span>
        </span>
        <Thermostat building={this.state.selectedBuilding} token={this.state.token} buildingID={this.state.buildingID} updateState={this.updateState} />
      </span>
    </span>);
    else if (this.state.notes)
      return <span id="menuNotes" className="flexCol flexGS mw420">
        <span className="smallWidthX3 flex360 flexCol flexGS">
          <textarea className="full boxShadowNormal" id="textareanotes" value={this.state.notesarea} readOnly></textarea>
          <input type="text" ref={this.setTextInputRef} id="notes" className="boxShadowNormal" value={this.state.note} onChange={this.addNoteOnChange} onKeyUp={this.addNote}></input>
        </span>
      </span>;
    else if (this.state.cards)
      return <span id="cards" className="flexRow smallWidthX4">
        <div className="cards-arrow" onClick={() => this.cardsArrow(true)}>
          <div id="cards-arrow-left">

          </div>
        </div>
        <span className=" flexGS"
          style={{
            overflowY: 'scroll',
          }}>

          <button className="navBtn" 
          onClick={()=>{
            this.loadPreviousPatient();
          }}><span className="left-arrow-prev"></span><span>ΠΡΟΗΓΟΥΜΕΝΟ </span></button>
          <button className="navBtn" onClick={()=>{
            this.loadNextPatient();
          }}><span>ΕΠΟΜΕΝΟ</span><span className="right-arrow-next"></span></button>
          <button className="navBtn" style={{marginLeft: "5%"}} onClick={()=>{
            this.loadNewPatient();
          }}>ΝΕΟ</button>
          <span className="">
          <br></br><br></br>
            <span style={{fontWeight: 'bold', color: 'black'}}>
              &nbsp;&nbsp;ΑΣΘΕΝΗΣ:&nbsp;
            </span>
            <span>
              {this.state.patient ? this.state.patient.name : ""}
            </span><br></br>
            <span style={{fontWeight: 'bold', color: 'black'}}>
              &nbsp;&nbsp;ΔΩΜΑΤΙΟ:&nbsp;
            </span>
            <span style={{fontWeight: 'bold', color: 'orange'}}>
              {this.state.selectedBuilding ? this.state.selectedBuilding.name : ""}
            </span>
          </span>
          {this.updateImage()}

        </span>
        <div className="cards-arrow" onClick={() => this.cardsArrow(false)}>
          <div id="cards-arrow-right">

          </div>
        </div>
      </span>;
  }

  render() {
    // NURSE REGISTER
    const registerEnter = (event) => {if (event.key === 'Enter') this.clickedRegister()};
    const register = <div className={this.state.failedLogin ? "shake" : "registerDiv"} id="login">
      <div id="selBar">
        {/* <h1 id="selLogin" onClick={this.selectedLogin}>Σύνδεση</h1>
        <span id="selMid">/</span> */}
        <h1 id="selRegister" style={{ color: "orange" }}>Εγγραφή Νοσοκόμας</h1>
      </div>
      <div>
        <input
          name="registerName"
          className="lgInput"
          type="text"
          placeholder="Ονοματεπώνυμο"
          onChange={this.handleChange}
          value={this.state.registerName}></input>
      </div>
      <div>
        <input
          name="registerEmail"
          className="lgInput"
          type="text"
          placeholder="Όνομα Χρήστη"
          onChange={this.handleChange}
          value={this.state.registerEmail}></input>
      </div>
      <div>
        <input
          name="registerPassword"
          className="lgInput"
          type="password"
          placeholder="Κωδικός"
          onChange={this.handleChange}
          value={this.state.registerPassword}></input>
      </div>
      <div>
        <input
          name="registerRepeatPassword"
          className="lgInput"
          type="password"
          placeholder="Επανάληψη Κωδικού"
          onKeyDown={(event) => registerEnter(event)}
          onChange={this.handleChange}
          value={this.state.registerRepeatPassword}></input>
      </div>
      <span onClick={this.clickedRegister} id="lgButton">→</span>
      {this.state.failedLogin && this.state.registerPassword !== this.state.registerRepeatPassword && <span id="lgError">Passwords do not match</span>}
      {this.state.failedLogin && !this.state.registerEmail && <span id="lgError">Email missing</span>}
      {this.state.failedLogin && !this.state.registerPassword && <span id="lgError">Password missing</span>}
    </div>

    // DOCTOR REGISTER
    const registerDoctor = <div className={this.state.failedLogin ? "shake" : ""} id="login">
      <div id="selBar">
        {/* <h1 id="selLogin" onClick={this.selectedLogin}>Σύνδεση</h1>
        <span id="selMid">/</span> */}
        <h1 id="selRegister" style={{ color: "orange" }}>Εγγραφή Ιατρού</h1>
      </div>
      <div>
        <input
          name="registerName"
          className="lgInput"
          type="text"
          placeholder="Ονοματεπώνυμο"
          onChange={this.handleChange}
          value={this.state.registerName}></input>
      </div>
      <div>
        <input
          name="registerEmail"
          className="lgInput"
          type="text"
          placeholder="Όνομα Χρήστη"
          onChange={this.handleChange}
          value={this.state.registerEmail}></input>
      </div>
      <div>
        <input
          name="registerPassword"
          className="lgInput"
          type="password"
          placeholder="PIN 4 ψηφία"
          onChange={this.handleChange}
          value={this.state.registerPassword}></input>
      </div>
      <div>
        <input
          name="registerRepeatPassword"
          className="lgInput"
          type="password"
          placeholder="Επανάληψη PIN"
          onChange={this.handleChange}
          value={this.state.registerRepeatPassword}></input>
      </div>
      <span onClick={this.clickedRegisterDoctor} id="lgButton">→</span>
      {this.state.failedLogin && this.state.registerPassword !== this.state.registerRepeatPassword && <span id="lgError">Passwords do not match</span>}
      {this.state.failedLogin && !this.state.registerEmail && <span id="lgError">Email missing</span>}
      {this.state.failedLogin && !this.state.registerPassword && <span id="lgError">Password missing</span>}
    </div>

    const loginEnter = (event) => {if (event.key === 'Enter') this.clickedLogin()};
    const login = <div className={this.state.failedLogin
      ? "shake"
      : ""} id="login">
      <div id="selBar" style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
        <img style={{width: 200, marginBottom: 0}}
        src={require('./images/REA-logo2gr.png')}
        ></img>
        <h1 id="selLogin" style={{ color: "orange", marginTop: 0 }}>Είσοδος Χρήστη</h1>
        {/* <span id="selMid">/</span>
        <h1 id="selRegister" onClick={this.selectedRegister}>Register</h1> */}
      </div>
      <div>
        <input
          name="loginEmail"
          className="lgInput"
          type="text"
          placeholder="username"
          onChange={this.handleChange}
          value={this.state.loginEmail}></input>
      </div>
      <div>
        <input
          name="loginPassword"
          className="lgInput"
          type="password"
          placeholder="password"
          onKeyDown={(event) => loginEnter(event)}
          onChange={this.handleChange}
          value={this.state.loginPassword}></input>
      </div>
      <span onClick={this.clickedLogin} id="lgButton">→</span>
      {this.state.failedLogin && <span id="lgError">Please check your info</span>}
    </div>
    const addBuilding = <div className={this.state.failedLogin
      ? "shake"
      : ""} id="login">
      <div id="selBar">
        <h1>Add new building</h1>
      </div>
      <div>
        <input
          name="addBuildingName"
          className="lgInput"
          type="text"
          placeholder="name"
          onChange={this.handleChange}
          value={this.state.addBuildingName}></input>
      </div>
      <div>
        <input
          name="addBuildingID"
          className="lgInput"
          type="text"
          placeholder="buildingID"
          onChange={this.handleChange}
          value={this.state.addBuildingID}></input>
      </div>
      {!this.state.loading && <span onClick={this.clickedAddBuilding} id="lgButton">→</span>}
      {this.state.loading && <span id="lgButton"><RingLoader size={35} color={"#F5A623"} loading={this.state.loading} /></span>}
      {this.state.failedLogin && <span id="lgError">Building id not valid</span>}
    </div>

    const bed = <span className="bigWidth flex120 viewBlock flexRow">

      <span className="flexCol flexEnd flex60">
        <img
          alt=""
          className="myContainerViewImage2 bedArrowImage"
          src={require('./images/arrow-drop-up.svg')}></img>
        <img
          alt=""
          className="myContainerViewImage2 bedArrowImage"
          src={require('./images/arrow-drop-down.svg')}></img>
      </span>

      <span className="flex120 flexCol flexAlignCenter">
        <img
          alt=""
          className="myContainerViewImage"
          src={require('./images/bed.png')}></img>
        <span className="myContainerViewWord">Κρεβάτι</span>
      </span>

      <span className="flexCol flexStart flex60">
        <img
          alt=""
          className="myContainerViewImage2 bedArrowImage"
          src={require('./images/arrow-drop-up.svg')}></img>
        <img
          alt=""
          className="myContainerViewImage2 bedArrowImage"
          src={require('./images/arrow-drop-down.svg')}></img>
      </span>

    </span>;

    const my = <span id="myContainer" className="flexRow mh640" >
      <span id="menuLeft" className="flexCol mw400 flexGS" >
        <span className="bigWidth flex120 viewBlock flexCol flexAlignCenter"
          onClick={() => {
            /*this.focus();*/
            this.setState({ notes: !this.state.notes, cards: false })
          }}
          style={{
            backgroundColor: this.state.notes ? "orange" : ""
          }}>
          <img
            alt=""
            className="myContainerViewImage"
            src={require('./images/notes_01.png')}></img>
          <span className="myContainerViewWord">Σημειώσεις</span>
        </span>
        <span className="bigWidth flex120 viewBlock flexCol flexAlignCenter"
          onClick={() => { this.setState({ cards: !this.state.cards, notes: false, insideRoom:true }) }}
          style={{
            backgroundColor: this.state.cards ? "orange" : ""
          }}>
          <img
            alt=""
            className="myContainerViewImage"
            src={require('./images/folder_01.png')}></img>
          <span className="myContainerViewWord">Καρτέλες</span>
        </span>
        {bed}
      </span>


      { typeof this.state.selectedBuilding !== "undefined" &&  typeof this.state.selectedBuilding.switches!== "undefined" &&
        this.centerRow()
      }

      {this.state.camera ?
        <span className="flexCol flexGS mw420">
          <span className="cameraWidth cameraFlex">
            <Camera
              closeCamera={this.closeCamera}
              token={this.state.token}
              buildingID={this.state.buildingID}
              devices={typeof this.state.selectedBuilding.devices !== 'undefined' ?
                this
                .state
                .selectedBuilding
                .devices
                .map(device => {
                  return { name: device.name, id: device.id }
                }) : []} />
          </span>
        </span> : <span id ="menuNurseImg" className="flexCol flexGS mw420">

          <img className="smallWidthX2 "
            alt=""
            src={require('./images/female_doctor.png')}></img>

        </span>
      }
    </span>;

    const buildingsView =
      <span className="buildingView">
        <span className="buildingViewInside" onMouseEnter={() => { this.setState({ onBuildingName: true }) }} onMouseLeave={() => { this.setState({ onBuildingName: false }) }}>



          {this.state.buildings.sort(this.sortByPropertyNameToIntInc).map(building => this.state.calling.findIndex(room => {
            return room === building.buildingID;
          }) < 0 ?
            <span key={building.buildingID} className="buildingViews viewBlock flexCol flexAlignCenter" onClick={() => { this.selectBuilding(building.name); }}>
              <span style={{width: 200}} className="buildingNamesW fitTxt">{building.name}</span>
            </span> :
            <span key={building.buildingID} className="buildingViews viewBlock flexCol flexAlignCenter" onClick={() => { this.selectCameraBuilding(building.name); }}
              style={{
                backgroundColor: this.state.doctor.indexOf(building.buildingID) < 0 ? this.state.help.indexOf(building.buildingID) < 0 ? "orange" : "red" : "rgba(0, 0, 255, 0.836)"
              }}>
              <span style={{width: 200}} className="buildingNamesW fitTxt">{building.name}</span>
            </span>
          )}
          {["102", "103", "104"].map(name =>
            <span key={name} className="fitTxt buildingViews viewBlock flexCol flexAlignCenter" >
              <span className="buildingNamesW">{name}</span>
            </span>)}
        </span>

        <span className="buildingViews viewBlock flexCol flexAlignCenter"
          onClick={this.selectedDoctor}
          style={{
            backgroundColor: this.state.doctor.length > 0 ? "rgba(0, 0, 255, 0.836)" : ""
          }}>
          <img alt="" id="doctorImage"
            src={require('./images/doctor.png')}
            style={{
              filter: " brightness(0.6)",
            }}
          />

        </span>

      </span>;

    const topBar = <div id="topBarContainer">
      <div className="topBar">
        {/* In case we want the room name to be on top left of screen after clicking Καρτέλες
        {this.state.selected === 1  &&  !this.state.insideRoom && <span className="buildingName ">{this.state.selectedBuilding.name}</span>} 

        {/* {this.state.selected === 1 &&  !this.state.insideRoom && <div className="vl"></div>} */}
        <span className="logoTopBar"></span>
          <span className="buildingNamesContainer ">
            <span className="divider"></span>
            <span className="buildingNames " onClick={this.selectedAllBuildings}>ΔΩΜΑΤΙΑ </span><span className="divider"></span>

            {/*<span className="buildingNames viewBlock" id="buildName" onClick={() => { this.selectBuilding(this.state.selectedBuilding.name); }}>
              {this.state.selectedBuilding.name}
                </span>*/}
            <span id="reg" className="buildingNames " onClick={this.selectedRegister}>ΕΓΓΡΑΦΗ ΝΟΣΟΚΌΜΑΣ</span><span className="divider"></span>
            {/* <span id="regDoc" className="buildingNames " onClick={this.selectedRegisterDoctor}>ΕΓΓΡΑΦΗ ΓΙΑΤΡΟΥ</span><span className="divider"></span> */}
          </span>
          <span className="buildingNames " onClick={this.clickedLogout}>ΑΠΟΣΥΝΔΕΣΗ</span>
      </div>
    </div>;

    const rootApp = <span id="rootApp">
      {this.state.selected === 1 && topBar}
      {this.state.selected === 2 && topBar}
      {this.state.selected === 4 && topBar}
      {this.state.selected === 5 && topBar}

      {this.state.selected === 0 && login}
      {this.state.selected === 1 && my}
      {this.state.selected === 2 && register}
      {this.state.selected === 3 && addBuilding}
      {this.state.selected === 4 && registerDoctor}
      {this.state.selected === 5 && buildingsView}
    </span>

    return (
      <BrowserRouter>
        <Route path="/" render={() => <span id="rootContainer">
        <ToastContainer
            position="top-right"
            autoClose={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
        />
          {rootApp}
        </span>
        } />
      </BrowserRouter>
    );
  }
}

export default App;
