import React from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import '../css/Camera.css';
import {FetchData} from '../Storage'

/*const customStyles = {
  content: {
    background: 'rgb(244, 244, 242)',
    top: '25%',
    left: '25%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-25%',
    transform: 'translate(0%, 0%)'
  }
};*/

class Camera extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false, url: "", };
    this.getVideoLink = "https://hm-api-v2.entranet.us:7656/housemate/video/requestVideoCall";
    //this.getVideoLink = "http://192.168.0.70:8071/housemate/video/requestVideoCall";
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidMount() {
    //component is being mounted each time camera opens
    if (this.props.devices.length > 0) {
      if (typeof this.props.devices[0].id !== undefined) {
        this.openModal(this.props.devices[0].id, this.getVideoLink)
        // axios
        //   .post(this.getVideoLink, {
        //     token: this.props.token,
        //     buildingID: this.props.buildingID,
        //     deviceID: this.props.devices[0].id,
        //     origin: 4
        //   })
        //   .then(function (response) {
        //     this.setState({
        //       url: response.data.data.ip,
        //     });
        //   }.bind(this));
      }
    }
  }

  componentWillUnmount() {
    if (this.props.closeCamera)
      this.props.closeCamera();
  }

  openModal = async (deviceId, link) => {
    console.log(deviceId);
    const DATA = {
      method: 'POST',
      
      body: JSON.stringify({
        buildingID: localStorage.getItem('buildingID'),
        deviceID: deviceId,
        origin: 4,
      }),
    };

    return FetchData(link, DATA).then((response) => {
        console.log("RESPONSE");
        console.log(response);
        this.setState({
        
          url: response.data.ip,
        })
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <iframe title="camera" id="cameraFrame" src={this.state.url}
        allow="microphone; camera" />
    );
  }
}

export default Camera;
