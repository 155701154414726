import React from 'react';
import axios from 'axios';
import FunctionalSkillsAssessment from '../models/FunctionalSkillsAssessment.js';
import url from './PatientPath';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import Progress from '../models/Progress.js';
// CSS Imports
import '../css/PatientInfo.css';
import '../css/PatientNote.css';
import '../css/Tab.css';

class PatientFuncSkills extends React.Component{
    constructor(props) {
        super(props);

        let funcSkillsK = new FunctionalSkillsAssessment();

        this.state = {
            functionalSkills: [funcSkillsK],
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.patient !== this.props.patient) {
            if(this.props.patient!==null && typeof this.props.patient.functionalSkills !=="undefined"){
            this.setState({
                functionalSkills:this.props.patient.functionalSkills
            });
            }
        }
    }
    componentDidMount() {
        if(this.props.patient!==null){
            axios.get(url+"/funcSkillsAssess/user/"+this.props.patient._id,
                    {headers: {"Authorization": localStorage.getItem("token")}}
            ).then(res=> {
            //let us = res.data;
            //console.warn(us);
            if (res.data.functionalSkillsAssessment == undefined){
                this.setState({functionalSkills: [new FunctionalSkillsAssessment()]});
            }else{
                let obj = res.data.functionalSkillsAssessment;
                this.setState({functionalSkills: obj});
            }
          }).catch(err=>{
            console.log(err);
          });
            this.setState({
                patient:this.props.patient
            });
        }
    }
    componentWillUnmount() {
    }
    getPropertyValue = (obj1, dataToRetrieve) => {
        return dataToRetrieve
          .split('.') // split string based on `.`
          .reduce(function(o, k) {
            return o && o[k]; // get inner property if `o` is defined else get `o` and return
          }, obj1) // set initial value as object
      }
    setPropertyValue = (obj1, dataToRetrieve, value) => {
        return dataToRetrieve
        .split('.') // split string based on `.`
        .reduce(function(o, k, index, r) {
            if (index === r.length-1){
            o[k] = value;
            console.warn(o[k]);
            }
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
        }, obj1) // set initial value as object
    }
    handleChange = (event,key, index) =>{
        this.setPropertyValue(this.state.functionalSkills[index], key, event.target.value);
        this.setState({
            functionalSkills: this.state.functionalSkills
        });
    }
    handleChangeProgress = (event,key, index1, index2) =>{
        this.setPropertyValue(this.state.functionalSkills[index1].progress[index2], key, event.target.value);
        // if anything changes update old-saved responsiblePersonnel to current logged in personnel
        this.state.functionalSkills[index1].personnelId = this.props.loggedInUser;
        this.setState({
            functionalSkills: this.state.functionalSkills
        });
    }
    handleDateChange = (date, key, index) =>{
        this.setPropertyValue(this.state.functionalSkills[index], key, date);
        this.setState({
            functionalSkills: this.state.functionalSkills
        });
    }
    handleDropdownChange(event, key) {
        this.setPropertyValue(this.state.functionalSkills, key, event.target.value);
        this.setState({
            functionalSkills: this.state.functionalSkills
        });
    }
    createRow = (description, repetitions, sets, deleteBtn)=>{
        return <tr><td>{description}</td><td>{repetitions}</td><td>{sets}</td><td>{deleteBtn}</td></tr>;
    }
    createInput = (description, key, index1)=>{
        if (key == "personnelId"){
            /* // will evaluate to true if value is not: null, undefined, NaN, empty string (""), 0, false
            if (!this.state.functionalSkills[index1][key]){
                this.state.functionalSkills[index1][key] = this.props.loggedInUser;
                this.setState({
                    functionalSkills: this.state.functionalSkills
                });
            } */
            return <input style={{marginLeft:0}} disabled='true' type = 'text' value={this.state.functionalSkills[index1][key]} onChange={(event)=>this.handleChange(event, key, index1)} placeholder={description}></input>;
        } else{
            return <input style={{marginLeft:0}} type = 'text' value={this.state.functionalSkills[index1][key]} onChange={(event)=>this.handleChange(event, key, index1)} placeholder={description}></input>;
        }
    }
    // createInputNumber = (description, key, index1)=>{
    //     return <NumberFormat className="numberInput" style={{marginLeft:0}} thousandSeparator={true}  value={this.state.functionalSkills[index1][key]} onChange={(event)=>this.handleChange(event, key, index1)} placeholder={description}></NumberFormat>;
    // }
    createInputProgress = (description, key, index1, index2)=>{
        return <input style={{marginLeft:0}} type = 'text' value={this.state.functionalSkills[index1].progress[index2][key]} onChange={(event)=>this.handleChangeProgress(event, key, index1, index2)} placeholder={description}></input>;
    }
    createInputNumberProgress = (description, key, index1, index2)=>{
        return <NumberFormat style={{marginLeft:0, maxWidth: 120}} thousandSeparator={true}  value={this.state.functionalSkills[index1].progress[index2][key]} onChange={(event)=>this.handleChangeProgress(event, key, index1, index2)} placeholder={description}></NumberFormat>;
    }
    createDate = (description, key, index1) =>{
        return <DatePicker selected = {typeof this.state.functionalSkills[index1][key] !== "undefined"?
        Date.parse(this.state.functionalSkills[index1][key]): new Date()} onChange = {(event)=> this.handleDateChange(event, key, index1)} placeholder={description}/>;
    }
    createDeleteBtn(index1){
        return <button className="iconOnlyBtn" style={{marginTop:0}} onClick={()=>{
            var nextState = this.state;
            nextState.functionalSkills.splice(index1, 1);
            this.setState(nextState)
            }}>
            <img className="imgBtn" src={require('../images/delete.png')}></img></button>
    }
    createDeleteBtnProgress(index1 ,index2){
        return <button className="iconOnlyBtn" onClick={()=>{
            var nextState = this.state;
            nextState.functionalSkills[index1].progress.splice(index2, 1);
            this.setState(nextState)
            }}>
            <img className="imgBtn" src={require('../images/delete.png')}></img></button>
    }
    createDropdownYesNo = (description, key)=>{
        return (
            <form>
              <label>
                {this.createRow(description, <select defaultValue="false" value={this.getPropertyValue(this.state.functionalSkills, key)} onChange={(event)=>this.handleDropdownChange(event, key)}>
                  <option value="true">ΝΑΙ</option>
                  <option value="false">ΟΧΙ</option>
                </select>)}
              </label>
            </form>
          );
    }
    createFuncSkillsTable(index1){
        return <table className="center" style={{textAlign: "center"}}><tbody>
        <tr>
            <td colSpan="2">Timestamp</td>
            <td colSpan="2">Personnel ID</td>
        </tr>
        <tr>
            <td colSpan="2">{this.createDate("Timestamp", "timestamp", index1)}</td>
            <td colSpan="2">{this.createInput("Personnel ID", "personnelId", index1)}</td>
            <td className="center">{this.createDeleteBtn(index1)}</td>
        </tr>
        <tr>
            <td colSpan='5'>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <button style={{marginRight: 5}} className="iconOnlyBtn" onClick={()=>this.addRowProgress(index1)}>
                        <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img>
                    </button>
                    <h3 style={{marginTop: 10, fontSize:20, textAlign:"center"}}>Πρόοδος</h3>
                </div>
            </td>
        </tr>
        {/* <tr>
            <td colSpan="5" className="center">
                <button className="iconOnlyBtn" onClick={()=>this.addRowProgress(index1)}>
                    <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img>
                </button>
            </td>
        </tr> */}
        <tr>
            <td>Τύπος Θεραπείας</td>
            <td>Κλίμακα</td>
            <td>Μέτρηση</td>
            <td></td>
        </tr>
        {this.state.functionalSkills[index1].progress.map((progress, index2) => this.createProgressTable(index1, index2),this)}
        {/* Seperator */}
        <tr><td style={{paddingBottom: 0}} colSpan="5"><hr className="solid"/></td></tr>
        </tbody></table>;
    }
    createProgressTable(index1, index2){
        return <tr>
            <td>{this.createInputProgress("Τύπος Θεραπείας", "therapyType", index1, index2)}</td>
            <td>{this.createInputProgress("Κλίμακα", "scale", index1, index2)}</td>
            <td colSpan="2">{this.createInputNumberProgress("Μέτρηση", "score", index1, index2)}</td>
            <td>{this.createDeleteBtnProgress(index1, index2)}</td>
        </tr>;
    }
    addRow(){
        var nextState = this.state;
        let newFuncSkill = new FunctionalSkillsAssessment();
        newFuncSkill.personnelId = this.props.loggedInUser;
        nextState.functionalSkills.push(newFuncSkill);
        this.setState(nextState);
    }
    addRowProgress(index1){
        var nextState = this.state;
        nextState.functionalSkills[index1].progress.push(new Progress());
        this.setState(nextState);
    }
    funcSkillsSaveBtn(){
        if (this.props.patient == null)
            return;
        this.state.functionalSkills.user_id = this.props.patient._id;
        this.props.patient.functionalSkills = this.state.functionalSkills;
        axios.put(url+"/funcSkillsAssess",{
            user_id: this.props.patient._id,
            userId: this.props.patient.userId,
            functionalSkillsAssessment: this.state.functionalSkills
        },{headers: {"Authorization": localStorage.getItem("token")}})
            .then(function (functionalSkills) {
                console.warn(functionalSkills);
            }).catch(err=>{
                console.warn(err);
            });
    }

    render(){
        return(
            <div className="flex-container">
                <div className="pageHead">
                    <img className="skillsImage" src={require('../images/notes_01.png')}></img>
                    <h3>Αξιολόγηση Λειτουργικών Δεξιοτήτων</h3>
                </div>
                {this.state.functionalSkills.map((skill, index1) => this.createFuncSkillsTable(index1),this)}

                <button className="addBtn" onClick={()=>this.addRow()}>
                    <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img><p className="txtBtn">ΠΡΟΣΘΗΚΗ</p>
                </button>
                <button className="addBtn" style={{fontSize:20, fontWeight:"bold", textAlign:"center"}} onClick={()=>{
                    this.funcSkillsSaveBtn();
                    }}>
                    ΑΠΟΘΗΚΕΥΣΗ
                </button>
            </div>
        );
    }
}
export default PatientFuncSkills;