import storage from "./Storage";
import { thermostatUrl } from './urls';

export function fetchThermostats() {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'GET',
        };
        return storage.FetchData(thermostatUrl + '/' + buildingID, DATA);
    }).catch((error) => {
        console.warn(error);
    });
}

export function updateThermostat(thermostat) {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'PUT',
            body: JSON.stringify({
                buildingID: buildingID,
                origin: 4,
                thermostat: thermostat
            }),
        };
        return storage.FetchData(thermostatUrl + '/' + thermostat.id, DATA);
    }).catch((error) => {
        console.warn(error);
    });
}