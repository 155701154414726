import Progress from "./Progress";

export default class FunctionalSkillsAssessment{
    constructor(timestamp, personnelId, progress){
        this.timestamp = timestamp || "";
        this.personnelId = personnelId || "";
        this.progress = progress || [new Progress()]
    }
}

// "functionalSkillsAssessment":[
//     {
//          "timestamp":"",
//       "personnelId":"",
//       "progress":[{
//              "therapyType":"μετακίνηση",
//              "scale":"Barthel",
//              "score":"5"
             
//        }]}]