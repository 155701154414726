import storage from "./Storage";
import { getBuildingDevicesUrl } from "./urls";

export const getBuildingDevices = () => {
    return storage.getBuildingID().then((buildingID) => {
        const DATA = {
            method: 'GET',
        };
        return storage.FetchData(getBuildingDevicesUrl + "/" + buildingID, DATA);
    }).catch((error) => {
        console.warn(error);
    });
}