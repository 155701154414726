import React from 'react';
import axios from 'axios';
import '../css/Thermostat.css';
import {updateThermostat} from '../ThermostatFunctions';

class Thermostat extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            // thermPosition: 0,
            // defaultThermostat: this.props.building.thermostats[this.thermPosition],
            // action: 0, 
            changeValues: false 
        };
         this.initTimer = undefined;
        // this.initThermostat = this.initThermostat.bind(this);
        this.changeLink = "https://hm-api.entranet.us:7656/housemate/home/switchUpdate";
       // this.getThermostat = this.getThermostat.bind(this);
       
    }

    getThermostat(building) {
        if(building.thermostats !== undefined) {
            let potentialThermostats = building.thermostats;
        
            if (potentialThermostats.length === 1)
                return potentialThermostats[0];
            else return null;
        }
       
    }

    changeThermostat(thermostat) {
        updateThermostat(thermostat).then(() => {
            console.log("Update thermostat");
        }).catch((error) => {console.warn(error)});
    }

    changeSetTemp(number) {
        let aThermostat = this.getThermostat(this.props.building);
        let value = parseInt(aThermostat.state);
        let newValue = value + number;
        if (newValue >=5 && newValue<= 32){
            aThermostat.state = newValue;
            this.setState({thermostat: aThermostat});
            this.countdown();
        }
       
        // let aThermostat = this.getThermostat(this.props.building);
        // console.log("-------");
        // console.log(aThermostat);
        // if (aThermostat === null) return;
        // let value = parseInt(aThermostat.state, 10);
        // let newValue = value + number;
        // aThermostat.state = newValue;
        // this.setState({ thermostat: aThermostat });

        // let building = this.props.building;
        // let switche = building.switches[building.switches.map(swit => swit.id).indexOf(this.getThermostat(this.props.building).id)];
        // if (switche)
        //     switche.state = newValue;
        // this.props.updateState({ selectedBuilding: building });
        // if (switche)
        //     axios.post(this.changeLink, {
        //         token: this.props.token,
        //         buildingID: this.props.buildingID,
        //         origin: 1,
        //         switch: this.getThermostat(this.props.building)
        //     });
    }

    countdown() {
        if(this.initTimer !== undefined){
            clearTimeout(this.initTimer);
        }

        this.initTimer = setTimeout(() => {this.changeThermostat(this.getThermostat(this.props.building))}, 1000);
    }

    render() {
        
        return (
            this.state.changeValues === false ?
                <span className="smallWidth viewBlock flex120 flexCol flexAlignCenter" onMouseEnter={() => { this.setState({ changeValues: true }) }}>
                    <img alt="" className="myContainerViewImage"
                        src={require('../images/thermostat.png')}
                        style={{
                            filter: this.state.thermostat ? "grayscale(0%)" : "hue-rotate(170deg) brightness(0.25)"
                        }}></img>
                    <span className="myContainerViewWord" style={{ color: "orange", filter: "saturate(0%) brightness(70%) contrast(500%)" }}>Θερμοστάτης</span>
                </span>
                :
                <span className="smallWidth flex120 viewBlock flexCol flexAlignCenter" onMouseLeave={() => { this.setState({ changeValues: false }) }}>
                    <span id="thTemp">{this.getThermostat(this.props.building) ? this.getThermostat(this.props.building).state : "--"}</span>
                    <span>
                        <span id="thLess" onClick={() => this.changeSetTemp(-1)}>&lt;</span>
                        <span id="thMore" onClick={() => this.changeSetTemp(1)}>&gt;</span>
                    </span>
                </span>
        );
    }
}

export default Thermostat;