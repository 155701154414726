import React from 'react';
//import dateformat from 'dateformat';
// CSS imports
import '../css/PatientNote.css';
import '../css/Tab.css';



class PatientDiet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            monday:[
                {
                    time:'07.30',
                    description:""},
                {
                    time:'11.00',
                    description:''
                }],
            tuesday:[
                {
                    time:'07.30',
                    description:""},
                {
                    time:'11.00',
                    description:''
                }]

        };

    }

    componentDidMount() {
        this.setState({
            date: new Date()
        });
    }

    componentWillUnmount() {
    }

    render() {
        return ( <div className="flex-container">

            <div className="pageHead">
                <img className="dietImage" src={require('../images/diet_doctor.png')}></img>
                <h3>Διαιτολόγιο</h3>
            </div>

            <table className="dietTable">
                <tr>
                    <th></th>
                </tr>
                <tr>
                    <td className="dateTime">Δευτέρα</td>
                    <td>
                        <div class = "borders" >

                        </div>
                    </td>
                </tr>
                    {this.state.monday.map((entry,index)=><tr><td className="dateTime">{entry.time}</td>
                    <td>{entry.description}</td></tr>)}

                <tr>
                    <td style={{paddingTop:20}} className="dateTime">Τρίτη</td>
                    <td style = {{paddingTop: 20}}>
                        <div class = "borders" >

                        </div>
                    </td>
                </tr>
                    {this.state.tuesday.map((entry,index)=><tr><td className="dateTime">{entry.time}</td>
                    <td>{entry.description}</td></tr>)}

                 <tr>
                    <td style={{paddingTop:20}} className="dateTime">Παρασκευή</td>
                    <td style={{paddingTop:20}}>
                        <div class = "borders" >
                        </div>
                    </td>
                </tr>
                    {this.state.tuesday.map((entry,index)=><tr><td className="dateTime">{entry.time}</td>
                    <td>{entry.description}</td></tr>)}
            </table>

        </div>
        );
    }
}

export default PatientDiet;
