import React from 'react';
import axios from 'axios';
import Activity from '../models/Activity.js';
import url from './PatientPath';
import NumberFormat from 'react-number-format';
// CSS imports
import '../css/PatientInfo.css';
import '../css/PatientNote.css';
import '../css/Tab.css';

class PatientActivities extends React.Component{
    constructor(props) {
        super(props);
        let activityK = new Activity();

        this.state = {
            activities: [activityK]
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.patient !== this.props.patient) {
            if(this.props.patient!=null && typeof this.props.patient.activity !=="undefined"){
            this.setState({
                activities:this.props.patient.activity
            });
        }
        }
    }
    componentDidMount() {
        if(this.props.patient!=null){
            axios.get(url+"/activity/user/"+this.props.patient._id,
                    {headers: {"Authorization": localStorage.getItem("token")}}
            ).then(res=> {
            // let us = res.data;
            // console.warn(us);
            if (res.data.activities == undefined){
                this.setState({activities: [new Activity()]});
            }else{
                this.setState({activities: res.data.activities});
            }
          }).catch(err=>{
            console.log(err);
          });
            this.setState({
                patient:this.props.patient
            });
        }
    }
    componentWillUnmount() {
    }

    getPropertyValue = (obj1, dataToRetrieve) => {
        return dataToRetrieve
          .split('.') // split string based on `.`
          .reduce(function(o, k) {
            return o && o[k]; // get inner property if `o` is defined else get `o` and return
          }, obj1) // set initial value as object
      }
    setPropertyValue = (obj1, dataToRetrieve, value) => {
        return dataToRetrieve
        .split('.') // split string based on `.`
        .reduce(function(o, k, index, r) {
            if (index == r.length-1){
            o[k] = value;
            console.warn(o[k]);
            }
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
        }, obj1) // set initial value as object
    }

    handleChangeActivity = (event, key, index)=>{
        this.state.activities[index][key] = event.target.value
        this.setState({
            activities: this.state.activities
        });
    }

    createRow = (description, repetitions, sets, deleteBtn)=>{
        return <tr><td>{description}</td><td>{repetitions}</td><td>{sets}</td><td>{deleteBtn}</td></tr>;
    }
    createInput = (description, key, index)=>{
        return <input style={{marginLeft:0}} type = 'text' value={this.state.activities[index][key]} onChange={(event)=>this.handleChangeActivity(event, key, index)} placeholder={description}></input>;
    }

    createInputNumber = (description, key, index)=>{
        return <NumberFormat className="numberInput" style={{marginLeft:0}} thousandSeparator={true}  value={this.state.activities[index][key]} onChange={(event)=>this.handleChangeActivity(event, key, index)} placeholder={description}></NumberFormat>;
    }
    addRow(){
        var nextState = this.state;
        nextState.activities.push(new Activity());
        this.setState(nextState)
    }
    createDeleteBtn(index){
        return <button className="addBtn" onClick={()=>{
            var nextState = this.state;
            nextState.activities.splice(index, 1);
            this.setState(nextState)
            }}>
            <img className="imgBtn" src={require('../images/delete.png')}></img><p className="txtBtn">ΔΙΑΓΡΑΦΗ</p></button>
    }
    createActTable(index){
        return <tr>
                <td>{this.createInput("Άσκηση", "activity_name", index)}</td>
                <td>{this.createInputNumber("Σετ", "sets", index)}</td>
                <td>{this.createInputNumber("Επαναλήψεις", "repetitions", index)}</td>
                <td>{this.createDeleteBtn(index)}</td>
            </tr>
    }
    activitiesSaveBtn(){
        if (this.props.patient == null)
            return;

        this.state.activities.user_id = this.props.patient._id;
        this.props.patient.activities = this.state.activities;
        axios.put(url+"/activity",{
            user_id: this.props.patient._id,
            userId: this.props.patient.userId,
            activities: this.state.activities
        },{headers: {"Authorization": localStorage.getItem("token")}})
            .then(function (activity) {
                console.warn(activity);
            }).catch(err=>{
                console.warn(err);
            });
    }

    render(){
        return(
            <div className="flex-container">
                <div className="pageHead">
                    <img className="activitiesImage" src={require('../images/profile_doctor.png')}></img>
                    <h3>Ασκήσεις</h3>
                </div>
                <table className="center">
                    <tbody>
                        <tr>
                            <td className="center" colSpan="4">
                                <button className="addBtn" onClick={()=>this.addRow()}>
                                    <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img><p className="txtBtn">ΠΡΟΣΘΗΚΗ</p>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th>Άσκηση</th>
                            <th>Σετ</th>
                            <th>Επαναλήψεις</th>
                            <th></th>
                        </tr>
                        {this.state.activities.map((activity, index) => this.createActTable(index), this)}
                        <tr>
                            <td className="center" colSpan="4">
                                <button className="addBtn" style={{fontSize:20, fontWeight:"bold", textAlign:"center"}} onClick={()=>{
                                    this.activitiesSaveBtn();
                                    }}>
                                    ΑΠΟΘΗΚΕΥΣΗ
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
export default PatientActivities;