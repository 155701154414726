import React from 'react';
import '../css/PatientInfo.css';
import PatientProfile from '../models/PatientProfile.js';
//import dateformat from 'dateformat';



class PatientInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            patient: new PatientProfile("ΚΩΣΤΑΚΗΣ ΠΑΠΑΠΑΣΧΑΛΗΣ", "Άγγαμος")

        };

    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    formatDate = (date) => {
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();

        if (month < 10){
            month = "0" + month;
        }
        if(day < 10){
            day = "0" + day;
        }
        
        return month + "/" + day + "/" + year;
    }


    createRow = (description, value)=>{
        return <tr className="pInfo"><td className="pLeftInfo">{description}</td><td className="pRightInfo">{value}</td></tr>;
    }

    render() {
        return ( <div className="flex-container">

        <div>
            <img className="infoImage" src={require('../images/profile_doctor.png')}></img>
            <h3 style={{fontSize:20, marginTop:5, marginBottom:40}}>στοιχεία ασθενούς</h3>
        </div>

        <table className="patientTable" style = {{textAlign: "left"}}>
            <thead>
                <tr>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {/*this.createRow("Ονοματεπώνυμο:", this.state.patient.name)}
                {this.createRow("Οικογενειακή κατάσταση:", this.state.patient.familyStatus)}
                {this.createRow("Όνομα συζύγου:", this.state.patient.husband)}
                {this.createRow("Διεύθυνση κατοικίας:", this.state.patient.address)}
                {this.createRow("Ημερομηνία Γέννησης:", this.state.patient.dateOfBirth)}
                {this.createRow("Τηλέφωνο:", this.state.patient.phone)}
                {this.createRow("Επικοινωνία:", this.state.patient.contact)*/}

                <tr><td colSpan='2'><div className="border"></div></td></tr>

                 {this.createRow("AMKA:", this.state.patient.AMKA)}
                 {this.createRow("Ασφάλιση:", this.state.patient.insurance)}
                 {this.createRow("Ημερομηνία εισητηρίου:", this.formatDate(this.state.patient.InDate))}
                 {this.createRow("Έως ημερομηνία:", this.formatDate(this.state.patient.OutDate))}
                 {this.createRow("Διάγνωση Εισόδου:", this.state.patient.diagnosis)}
                 {this.createRow("Θεραπών Ιατρός και ειδικότητα:", this.state.patient.doctor)}
                 {this.createRow("Ειδικότητα ιατρού", this.state.patient.speciality)}
                 {this.createRow("Κατηγορία περιστατικού και ημερομηνία:", this.state.patient.incident)}
                 {this.createRow("Ημερομηνία περιστατικού:", this.formatDate(this.state.patient.incidentDate))}

                 <tr><td colSpan='2'><div className="border"></div></td></tr>

                <tr className = "pInfo" >
                    <td colSpan="2">
                        <p className="p">Επιπλέον</p>
                        <p className="p">1. Αναλυτική διάγνωση εισόδου:</p>
                    </td>
                </tr>
            </tbody>
        </table>
            </div>
        );
    }
}

export default PatientInfo;
