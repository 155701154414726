import MedicationSchema from "./MedicationSchema";
export default class Medication{
    constructor(responsiblePersonnel, medicationName, comment, encodingEOF, medicationSchema, relatedHealthCondition, relatedInjury, startingDate, endingDate) {
        this.responsiblePersonnel = responsiblePersonnel;
        this.medicationName = medicationName;
        this.comment = comment;
        this.encodingEOF = encodingEOF;
        this.medicationSchema = medicationSchema || [new MedicationSchema()];
        this.relatedHealthCondition = relatedHealthCondition || [];
        this.relatedInjury = relatedInjury || [];
        this.startingDate = startingDate || new Date();
        this.endingDate = endingDate || new Date();

    }

    /*
    medicationSchema: [{
              "medicationAmount": "xxx",
              "medicationDay": "xxx",
              "medicationTime": "xxx"
            }]
    */
}