import React from 'react';
import Medication from '../models/Medication';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import Dropdown from 'react-dropdown';
import axios from 'axios';
import url from './PatientPath';
import MultipleValueTextInput from 'react-multivalue-text-input';
// CSS Imports
import '../css/PatientNote.css';
import '../css/Tab.css';
import 'react-dropdown/style.css';
import MedicationSchema from '../models/MedicationSchema';
// Unused Imports
/*
import dateformat from 'dateformat';
import PatientInputInfo from './PatientInputInfo';
import {sendMedicines} from '../models/Rea';
*/

class PatientInputMedicine extends React.Component {
    constructor(props) {
        super(props);

        let medicineK = new Medication();

        this.state = {
            medication: [medicineK]
        };

    }

    componentDidUpdate(prevProps) {
        if (prevProps.patient !== this.props.patient) {
            if(this.props.patient!=null && typeof this.props.patient.medication !=="undefined"){

                this.setState({
                    medication:this.props.patient.medication
                });
        }
        }
    }

    componentDidMount() {
        // no need to do axios.get patient here because that is done in App.js for patient
        if(this.props.patient!=null && typeof this.props.patient.medication !=="undefined"){
            this.setState({
                medication:this.props.patient.medication
            });
        }
    }

    componentWillUnmount() {

    }

    getPropertyValue = (obj1, dataToRetrieve) => {
        return dataToRetrieve
          .split('.') // split string based on `.`
          .reduce(function(o, k) {
            return o && o[k]; // get inner property if `o` is defined else get `o` and return
          }, obj1) // set initial value as object
      }
    setPropertyValue = (obj1, dataToRetrieve, value) => {
        return dataToRetrieve
        .split('.') // split string based on `.`
        .reduce(function(o, k, index, r) {
            if (index === r.length-1){
            o[k] = value;
            console.warn(o[k]);
            }
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
        }, obj1) // set initial value as object
    }

    handleDateChange = (date, key, index1) => {
        this.state.medication[index1][key] = date;
        this.setState({
            medication: this.state.medication
        });

    }
    handleTimeChange = (date, key, index1, index2) => {
        this.state.medication[index1].medicationSchema[index2][key] = date;
        this.setState({
            medication: this.state.medication
        });

    }

    handleChangeMedicine = (event, key, index)=>{
        this.state.medication[index][key] = event.target.value;
        // if anything changes update old-saved responsiblePersonnel to current loggen in personnel
        this.state.medication[index].responsiblePersonnel = this.props.loggedInUser;
        this.setState({
            medication: this.state.medication
        });
    }
    handleChangeSchema = (event, key, index1, index2)=>{
        this.state.medication[index1].medicationSchema[index2][key] = event.target.value;
        // if anything changes update old-saved responsiblePersonnel to current loggen in personnel
        this.state.medication[index1].responsiblePersonnel = this.props.loggedInUser;
        this.setState({
            medication: this.state.medication
        });
    }

    handleDropdownChange(event, key, index1, index2) {
        this.state.medication[index1].medicationSchema[index2][key] = event.target.value;
        this.setState({
            medication: this.state.medication
        });
    }
    handleChecklist(values, key, index1){
        console.warn(values);
        var tmp = [];
        var i = 0;
        values.forEach((element) =>{
            console.warn(element.id);
            tmp[i] = element.id;
            i++
        });

        this.state.medication[index1][key] = tmp;
        this.setState({
            medication: this.state.medication,
        });
    }


    handleMultiValueChange(index1, key, allItems){
        this.state.medication[index1][key] = allItems;
        this.setState({
            medication: this.state.medication
        });
    }

    createInput = (description, key, index)=>{
        if (key == "responsiblePersonnel"){
            // will evaluate to true if value is not: null, undefined, NaN, empty string (""), 0, false
            if (!this.state.medication[index][key]){
                this.state.medication[index][key] = this.props.loggedInUser;
                this.setState({
                    medication: this.state.medication
                });
            }
            return <input style={{marginLeft:0, minWidth: 150, maxWidth: 300}} disabled = {true} type = 'text' value={this.state.medication[index][key]} onChange={(event)=>this.handleChangeMedicine(event, key, index)} placeholder={description}></input>;
        } else{
            return <input style={{marginLeft:0, minWidth: 150, maxWidth: 300}} type = 'text' value={this.state.medication[index][key]} onChange={(event)=>this.handleChangeMedicine(event, key, index)} placeholder={description}></input>;
        }
    }

    createInputNumber = (key, index)=>{
        return <NumberFormat className="numberInput" style={{marginLeft:0}} thousandSeparator={true}  value={this.state.medication[index][key]} onChange={(event)=>this.handleChangeMedicine(event, key, index)}></NumberFormat>;
    }

    createDate = (key, index) =>{
        const ExampleCustomInput = ({value, onClick}) =>(<button className="dateTime" onClick={onClick}><span>{value}</span></button>);
        return (<DatePicker style={{margin:0, padding: 0}} selected ={Date.parse(this.state.medication[index][key])} onChange={(event)=>this.handleDateChange(event, key, index)} customInput={<ExampleCustomInput />}/>);
    }

    createDropDown = (key, index)=>{
        return (<Dropdown options={this.state.options} value={this.state.medication[index][key]} onChange={(event)=>this.handleDropDown(event, key, index)} />);
    }

    createRow = (date, endDate, time, name, unit, quantity, providing)=>{
        return <tr><td><p style={{marginBottom:5}}>Από:</p><p style={{marginTop:8}}>Έως:</p></td><td className="medDate">{date}{endDate}</td>
        <td className="medTime">{time}</td><td style={{paddingRight:10}}>{name}</td><td className="prov">{unit}</td>
        <td id="quantityInput">{quantity}</td><td className="prov">{providing}</td></tr>;
    }

    addRow(){
        var nextState = this.state;
        nextState.medication.push(new Medication());
        this.setState(nextState)
    }
    addRowMedSchema(index1){
        var nextState = this.state;
        nextState.medication[index1].medicationSchema.push(new MedicationSchema());
        this.setState(nextState)
    }

    // TODO fix related health conditions, injuries not appearing from db (need to do in component did mount set values)
    createMultiValueInput(key, index1){
        return <MultipleValueTextInput style={{maxWidth: "150px"}}
            values={this.state.medication[index1][key]}
            onItemAdded={((item, allItems) => this.handleMultiValueChange(index1, key, allItems))}
            onItemDeleted={((item, allItems) => this.handleMultiValueChange(index1, key, allItems))}
            // label="Items"
            name="item-input"
            placeholder={key}
        ></MultipleValueTextInput>
    }

    handleSelectChange(selectedOption, key, index1){
        this.state.medication[index1][key] = selectedOption;
        this.setState({
            medication: this.state.medication
        });
    }



    createTimeSchema = (key, index1, index2) =>{
        return (<DatePicker style={{marginLeft:0}} selected={Date.parse(this.state.medication[index1].medicationSchema[index2][key])} onChange={(event)=>this.handleTimeChange(event, key, index1, index2)}
        showTimeSelect showTimeSelectOnly timeCaption="Time" dateFormat="hh:mm aa" timeIntervals={5}/>);
    }
    createInputSchema = (key, index1, index2)=>{
        return <input style={{marginLeft:0}} type = 'text' value={this.state.medication[index1].medicationSchema[index2][key]} onChange={(event)=>this.handleChangeSchema(event, key, index1, index2)}></input>;
    }
    createInputNumberSchema = (key, index1, index2)=>{
        return <NumberFormat className="numberInput" thousandSeparator={true}  value={this.state.medication[index1].medicationSchema[index2][key]} onChange={(event)=>this.handleChangeSchema(event, key, index1, index2)}></NumberFormat>;
    }

    createDropdownDaySchema = (key, index1, index2)=>{
        return (
            <form>
              <label>
                <select style={{maxWidth: 150}} value={this.getPropertyValue(this.state.medication[index1].medicationSchema[index2], key)} onChange={(event)=>this.handleDropdownChange(event, key, index1, index2)}>
                    <option value="empty"></option>
                    <option value="Καθημερινά">Καθημερινά</option>
                    <option value="Δευτέρα">Δευτέρα</option>
                    <option value="Τρίτη">Τρίτη</option>
                    <option value="Τετάρτη">Τετάρτη</option>
                    <option value="Πέμπτη">Πέμπτη</option>
                    <option value="Παρασκευή">Παρασκευή</option>
                    <option value="Σάββατο">Σάββατο</option>
                    <option value="Κυριακή">Κυριακή</option>
                </select>
              </label>
            </form>
          );
    }
    createDeleteBtn(index1){
        return <button className="addBtn" style={{marginTop:0}} onClick={()=>{
            var nextState = this.state;
            nextState = nextState.medication.splice(index1, 1);
            this.setState(nextState)
            }}>
            <img className="imgBtn" src={require('../images/delete.png')}></img><p className="txtBtn">ΔΙΑΓΡΑΦΗ</p></button>
    }
    createDeleteBtnSchema(index1, index2){
        return <button className="iconOnlyBtn" style={{marginTop:0}} onClick={()=>{
            var nextState = this.state;
            nextState = nextState.medication[index1].medicationSchema.splice(index2, 1);
            this.setState(nextState)
            }}>
            <img className="imgBtn" src={require('../images/delete.png')}></img></button>
    }
    createDropdownSchema(key, index1, index2){
        return <form>
            <label>
            <select style={{width: 80}} value={this.getPropertyValue(this.state.medication[index1].medicationSchema[index2], key)} defaultValue="units" onChange={(event)=>this.handleDropdownChange(event, key, index1, index2)}>
                <option value="units"></option>
                <option value="g">g</option>
                <option value="mg">mg</option>
                <option value="mcg">mcg</option>
                <option value="ml">ml</option>
                <option value="cc">cc</option>
            </select>
            </label>
        </form>;
    }
    createMedicationTable(index1){
        return [
            <tr><td colSpan='7'><div className="border"></div></td></tr>,
            <tr>
                <td className="center" colSpan='7'>
                    {this.createDeleteBtn(index1)}
                </td>
            </tr>,
            <tr>
                <td>
                    <p style={{marginBottom:5}}>Από:</p>
                    <p style={{marginTop:8}}>Έως:</p>
                </td>
                <td className="medDate">
                    {this.createDate("startingDate", index1)}
                    {this.createDate("endingDate", index1)}
                </td>
                <td className="center">
                    {this.createInput("Υπεύθυνος", "responsiblePersonnel", index1)}
                </td>
                <td className="center">
                    {this.createInput("Φάρμακο", "medicationName", index1)}
                </td>
                <td className="center">
                    {this.createInput("Κωδικός ΕΟΦ", "encodingEOF", index1)}
                </td>
                <td className="center">
                    {this.createMultiValueInput("relatedHealthCondition", index1)}
                </td>
                <td className="center">
                    {this.createMultiValueInput("relatedInjury", index1)}
                </td>
            </tr>,
            <tr>
                <td className="center" style={{paddingBottom: 20}} colSpan='7'>
                {this.createInput("Σημείωση", "comment", index1)}
                </td>
            </tr>,
            <tr>
                <td style={{paddingBottom: 0}} colSpan='7'>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <button style={{marginRight: 5}} className="iconOnlyBtn" onClick={()=>this.addRowMedSchema(index1)}>
                            <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img>
                        </button>
                        <h3 style={{marginTop: 10, fontSize:20, textAlign:"center"}}>Medication Schema</h3>
                    </div>
                </td>
            </tr>,
            <tr>
                <th></th>
                <th></th>
                <th style={{fontSize:20, paddingLeft:20}}>Ποσότητα</th>
                <th style={{fontSize:20}}>Μον. μέτρησης</th>
                <th style={{fontSize:20}}>Ημέρα</th>
                <th className="medTime" style={{fontSize:20}}>Ώρα</th>
            </tr>,
            this.state.medication[index1].medicationSchema.map((schema, index2) => this.createMedicationSchema(index1, index2),this)
        ];
    }
    createMedicationSchema(index1, index2){
        return <tr>
            <td></td>
            <td></td>
            <td className="center">
                {this.createInputNumberSchema("medicationAmount", index1, index2)}
            </td>
            <td className="center">
                {this.createDropdownSchema("measurementUnit", index1, index2)}
            </td>
            <td className="center">
                {this.createDropdownDaySchema("medicationDay", index1, index2)}
            </td>
            <td className="medTime center">
                {this.createTimeSchema("medicationTime", index1, index2)}
            </td>
            <td>
                {this.createDeleteBtnSchema(index1, index2)}
            </td>
        </tr>;
    }
    medicineSaveBtn(){
        if (this.props.patient == null){
            return;
        }
        console.warn(this.state.medication);
        this.props.patient.medication = this.state.medication;
        axios.put(url+"/patient",
        this.props.patient,{headers: {"Authorization": localStorage.getItem("token")}})
        .then(function (patient) {
            console.log(patient);
        }).catch(err=>{
            console.log(err);
        });
    }

    render() {
        return (
        <div className="flex-container">
        <div className="pageHead">
            <img className="medImage" src={require('../images/medicines_doctor.png')}></img>
            <h3>Φαρμακευτική Αγωγή</h3>
            <p style={{fontSize:20, marginTop:5, marginBottom:10}}>ΗΜΕΡΗΣΙΟ ΠΡΟΓΡΑΜΜΑ</p>
        </div>
        <table className="medTable" style = {{textAlign: "left"}}>
            <thead>
                <tr style={{paddingBottom:0}}>
                    <th></th>
                    <th style={{fontSize:20}}>Ημερομηνία</th>
                    <th style={{fontSize:20}}>Υπεύθυνος</th>
                    <th style={{fontSize:20}}>Φάρμακο</th>
                    <th style={{fontSize:20}}>Κωδικός ΕΟΦ</th>
                    <th style={{fontSize:20}}>Κατάσταση Υγείας</th>
                    <th style={{fontSize:20}}>Σχ.Τραυματισμοί</th>

                </tr>
            </thead>
            <tbody>

                {this.state.medication.map((medication, index1) => this.createMedicationTable(index1))}

                {/* {this.state.medication.map((medication, index) => this.createRow(this.createDate("date", index),
                this.createDate("endDate", index),
                this.createTime("date", index),
                this.createInput("Φάρμακο", "name", index),
                this.createDropDown("unit", index),
                this.createInputNumber("quantity", index),
                this.createInput("Παροχή","providing", index)), this)
                } */}
            </tbody>
        </table>
        <button className="addBtn" onClick={()=>this.addRow()}>
            <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img><p className="txtBtn">ΠΡΟΣΘΗΚΗ</p>
        </button>
        <button className="addBtn" style={{fontSize:20, fontWeight:"bold", textAlign:"center"}} onClick={()=>{
            this.medicineSaveBtn();
            }}>
            ΑΠΟΘΗΚΕΥΣΗ
        </button>
        </div>
        );
    }
}

export default PatientInputMedicine;
