import SleepQualityAssement from "./SleepQualityAssessment";
import Preferences from "./Preferences";
import StepsMeasurement from "./StepsMeasurement";
import MinutesWalkingMeasurement from "./MinutesWalkingMeasurement";
import Meal from "./Meal";
import Extras from "./Extras"
import RehabilitationReason from "./RehabilitationReason";


export default class PatientProfile{
    constructor(name, familyStatus, spouseName, address, dateOfBirth = new Date(), phone, contact,
        AMKA, insurance, /*InDate = new Date(), OutDate = new Date(), diagnosis,*/ doctor, speciality,
        incident, incidentDate = new Date(),userId, diabetic, pain, meal, preferences, receivedMeals,
        receivedDrinks, receivedMedication, receivedPainkiller, sleepQualityAssessment, stepsMeasurement,
        minutesWalkingMeasurement, rehabilitationReason, extras) {
        this.name = name;
        this.familyStatus = familyStatus;
        this.spouseName = spouseName;
        this.address = address;
        this.dateOfBirth = dateOfBirth;
        this.phone = phone;
        this.contact = contact;
        this.AMKA = AMKA;
        this.insurance = insurance;
        //this.InDate = InDate;
        //this.OutDate = OutDate;
        //this.diagnosis = diagnosis;
        this.doctor = doctor;
        this.speciality = speciality;
        this.incident = incident;
        this.incidentDate = incidentDate;
        this.userId = userId;
        this.diabetic = diabetic;
        this.pain = pain;
        this.meal = meal || [new Meal()];
        this.preferences = preferences || new Preferences();
        this.receivedMeals = receivedMeals;
        this.receivedDrinks = receivedDrinks;
        this.receivedMedication = receivedMedication;
        this.receivedPainkiller = receivedPainkiller;
        this.sleepQualityAssessment = sleepQualityAssessment || [new SleepQualityAssement()];
        this.StepsMeasurement = stepsMeasurement || [new StepsMeasurement()];
        this.MinutesWalkingMeasurement = minutesWalkingMeasurement || [new MinutesWalkingMeasurement()];
        this.rehabilitationReason = rehabilitationReason || new RehabilitationReason();
        this.extras = extras || new Extras();
    }


}


