import Session from "./Session";

export default class Treatment{
    constructor(treatmentName, startingDate, endingDate, benefits, session){
        this.treatmentName = treatmentName || "";
        this.startingDate = startingDate || new Date();
        this.endingDate = endingDate || new Date();
        this.benefits = benefits || "";
        this.Session = session || new Session();
    }
}