import React from 'react';
import DatePicker from 'react-datepicker';
import DietProfile from '../models/DietProfile';
import axios from 'axios';
import url from './PatientPath';
import PatientProfile from '../models/PatientProfile';
//import Meal from '../models/Meal';
import Meals from '../models/Meals';
// CSS Imports
import '../css/PatientNote.css';
import '../css/Tab.css';
import 'react-dropdown/style.css';
import MealSchedule from '../models/MealSchedule';
import MealDaySchedule from '../models/MealDaySchedule';
// Unused Imports
/*
import dateformat from 'dateformat';
import MedicineProfile from '../models/DietProfile';
import NumberFormat from 'react-number-format';
import Dropdown from 'react-dropdown';
import PatientDiet from './PatientDiet';
import {sendMeals} from '../models/Rea';
*/

class PatientInputMedicine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // // PATIENT
            // patient: new PatientProfile(),
            // selectedPatientMeal: new Meal(), // for patient

            // FOR ALL PATIENTS
            selectedMealType: '',
            days: ["Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο", "Κυριακή"],
            mealTypes: ['', 'LEVINE ΤΑΚΤΙΚΟ', 'LEVINE ΔΙΑΒΗΤΙΚΟ', 'LEVINE ΑΝΑΛΟ', 'LEVINE ΥΠΕΡΛΕΥΚΩΜΑΤΟΥΧΟ', 'LEVINE ΥΠΕΡΘΕΡΜΙΔΙΚΟ', 'LEVINE ΔΙΑΡΡΟΙΑ', 'LEVINE ΑΛΙΠΟ',
            'ΥΔΑΡΗΣ',
            'ΜΑΛΑΚΗ ΤΑΚΤΙΚΟ', 'ΜΑΛΑΚΗ ΑΝΑΛΟ', 'ΜΑΛΑΚΗ ΑΝΑΛΟ ΔΙΑΒΗΤΙΚΟ', 'ΜΑΛΑΚΗ ΔΙΑΒΗΤΙΚΟ', 'ΜΑΛΑΚΗ ΕΛΑΦΡΥ', 'ΜΑΛΑΚΗ ΑΛΙΠΟ', 'ΜΑΛΑΚΗ ΥΠΕΛΕΥΚΩΜΑΤΟΥΧΟ', 'ΜΑΛΑΚΗ ΥΠΕΡΘΕΡΜΙΔΙΚΟ', 'ΜΑΛΑΚΗ SINTROM', 'ΜΑΛΑΚΗ ΧΟΛΗ', 'ΜΑΛΑΚΗ SINTROM ΔΙΑΡΡΟΙΑ', 'ΜΑΛΑΚΗ ΔΙΑΡΡΟΙΑ', 'ΜΑΛΑΚΗ ΔΙΑΒΗΤΙΚΟ ΔΙΑΡΡΟΙΑ', 'ΜΑΛΑΚΗ ΔΙΑΒΗΤΙΚΟ ΝΕΦΡΟΠΑΘΗ',
            'ΤΑΚΤΙΚΟ', 'ΤΑΚΤΙΚΟ ΔΙΑΒΗΤΙΚΟ', 'ΤΑΚΤΙΚΟ ΝΕΦΡΟΠΑΘΗ', 'ΤΑΚΤΙΚΟ ΜΑΛΑΚΗ ΝΕΦΡΟΠΑΘΗ', 'ΤΑΚΤΙΚΟ ΔΙΑΒΗΤΙΚΟ ΝΕΦΡΟΠΑΘΗ', 'ΤΑΚΤΙΚΟ ΑΝΑΛΟ', 'ΤΑΚΤΙΚΟ ΑΝΑΛΟ ΔΙΑΒΗΤΙΚΟ', 'ΤΑΚΤΙΚΟ ΥΠΕΡΛΕΥΚΩΜΑΤΟΥΧΟ', 'ΤΑΚΤΙΚΟ ΥΠΕΡΘΕΡΜΙΔΙΚΟ', 'ΤΑΚΤΙΚΟ ΑΛΙΠΟ', 'ΤΑΚΤΙΚΟ SINTROM', 'ΤΑΚΤΙΚΟ ΥΠΟΘΕΡΜΙΔΙΚΟ', 'ΤΑΚΤΙΚΟ ΥΠΕΡΠΡΩΤΕΪΝΙΚΟ', 'ΤΑΚΤΙΚΟ ΔΙΑΒΗΤΙΚΟ ΧΟΛΗ', 'ΤΑΚΤΙΚΟ ΔΙΑΡΡΟΙΑ', 'ΤΑΚΤΙΚΟ ΔΙΑΒΗΤΙΚΟ ΔΙΑΡΡΟΙΑ', 'ΤΑΚΤΙΚΟ ΕΛΑΦΡΑ', 'ΤΑΚΤΙΚΟ SINTROM ΔΙΑΡΡΟΙΑ',
            'ΑΛΕΣΜΕΝΟ ΤΑΚΤΙΚΟ', 'ΑΛΕΣΜΕΝΟ ΕΛΑΦΡΑ', 'ΑΛΕΣΜΕΝΟ ΔΙΑΒΗΤΙΚΟ', 'ΑΛΕΣΜΕΝΟ ΑΛΙΠΟ', 'ΑΛΕΣΜΕΝΟ ΔΙΑΡΡΟΙΑ', 'ΑΛΕΣΜΕΝΟ ΑΝΑΛΟ', 'ΑΛΕΣΜΕΝΟ SINTROM'
            ],
            selectedMealSchedule: [],
            meals: [] // Meals.js
        };
    }

    /* Component Methods */
    componentDidUpdate(prevProps) {
        /*if (prevProps.patient !== this.props.patient) {
            if(this.props.patient!== null){
                // let selectedPatientMeal = null;
                // if (typeof this.props.patient.meal !=="undefined")
                //     selectedPatientMeal = this.props.patient.meal[this.props.patient.meal.length-1]; // the selectedPatientMeal is the last one in the array
                this.setState({
                    //week:this.props.patient.meals,
                    patient: this.props.patient,
                    //selectedPatientMeal: selectedPatientMeal ? selectedPatientMeal : new Meal()
                });
        }
        }*/
    }

    componentDidMount() {
        this.getMeals();
        /*if(this.props.patient!=null){
            let selectedPatientMeal = null;
                if (typeof this.props.patient.meal !=="undefined")
                    selectedPatientMeal = this.props.patient.meal[this.props.patient.meal.length-1]; // the selectedPatientMeal is the last one in the array
            this.setState({
                //week:this.props.patient.meals,
                patient: this.props.patient,
                //selectedPatientMeal: selectedPatientMeal ? selectedPatientMeal : new Meal()
            });
        }*/
    }

    componentWillUnmount() {
    }
    /* Component Methods */

    getMeals(){
        axios.get(url+"/meals/",
                {headers: {"Authorization": localStorage.getItem("token")}}
        ).then(res=> {
                if(res.data){
                    console.warn(res.data);
                    this.setState({
                        meals: res.data
                    });
                }
            })
            .catch(err=>{
                console.log(err);
            });
    }

    handleDateChange = (date, j, index) => {
        this.state.selectedMealSchedule[j].daySchedule[index].time = date;
        this.setState({
            selectedMealSchedule: this.state.selectedMealSchedule
        });

    }

    handleChangeDiet = (event, j, index)=>{
        if(event.target.value != null)
            this.state.selectedMealSchedule[j].daySchedule[index].mealName = event.target.value;
        else
            this.state.selectedMealSchedule[j].daySchedule[index].mealName = "";
        this.setState({
            selectedMealSchedule: this.state.selectedMealSchedule
        });
    }


    createDietInput = (description, j, index)=>{
        return <input style={{marginLeft:0}} type = 'text' value={this.state.selectedMealSchedule[j].daySchedule[index].mealName} onChange={(event)=>this.handleChangeDiet(event, j, index)} placeholder={description}></input>;
    }


    createTime = (j, index) =>{
        // TODO convert String time to Date.parse
        const ExampleCustomInput = ({value, onClick}) => (<button className="dateTime" onClick={onClick}>{value}</button>);
        return (<DatePicker style={{marginLeft:0}} selected={Date.parse(this.state.selectedMealSchedule[j].daySchedule[index].time)} onChange={(event)=>this.handleDateChange(event, j, index)} customInput={<ExampleCustomInput/>}
        showTimeSelect showTimeSelectOnly timeCaption="Time" dateFormat="h:mm aa" timeIntervals={15}/>);
    }


    createSchedule = (day)=>{
            let daySchedule = [];
            let dayScheduleIndex = -1;
            for (let j=0;j<this.state.selectedMealSchedule.length;j++){
                if (this.state.selectedMealSchedule[j].dayName == day){
                    daySchedule = this.state.selectedMealSchedule[j].daySchedule;
                    dayScheduleIndex = j;
                }
            };
            return <tbody>
                    <tr>
                        <td>{day}</td><td style={{textAlign:'right'}}><div className="borders"></div>
                        </td>
                    </tr>
                    {daySchedule.map((meal,index) => {
                                return this.createMeal(
                                    this.createTime(dayScheduleIndex, index),
                                    this.createDietInput("Δίαιτα", dayScheduleIndex, index),
                                    this.createDeleteBtn(dayScheduleIndex, index)
                                );
                            }, this
                        )
                    }
                    <tr>
                        <td colSpan="2" style={{textAlign:'right'}}>
                            <button className="addBtn" onClick={()=>this.addMealToDay(day, dayScheduleIndex)}>
                                <img className="imgBtn" src={require('../images/add_symbol-01.png')}></img><p className="txtBtn">ΠΡΟΣΘΗΚΗ</p>
                            </button>
                        </td>
                    </tr>
                </tbody>

    }

    createMeal = (time, description, deleteBtn)=>{
        return <tr><td id="dietTime">{time}</td><td>{description}&nbsp;{deleteBtn}</td></tr>
    }

    addRow(i){
        var nextState = this.state;
        let diet = new DietProfile();
        diet.date.setDate(diet.date.getDate() + ((i) - diet.date.getDay()));
        nextState.week.push(diet);
        this.setState(nextState);
    }
    addMealToDay(day, dayScheduleIndex){
        // let dayScheduleIndex = -1;
        // for (let j=0;j<this.state.mealSchedule.length;j++){
        //     if (this.state.mealSchedule[j].dayName == day){
        //         dayScheduleIndex = j;
        //     }
        // };
        if (this.state.selectedMealType == ''){
            return alert("ΕΠΙΛΕΞΤΕ ΤΥΠΟ ΓΕΥΜΑΤΟΣ ΓΙΑ ΝΑ ΕΙΣΑΓΕΤΕ ΓΕΥΜΑΤΑ");
        }
        let meal = new MealDaySchedule(new Date());
        if (dayScheduleIndex == -1){
            let mealSchedule = new MealSchedule(day);
            mealSchedule.daySchedule.push(meal);
            this.state.selectedMealSchedule.push(mealSchedule);
        } else{
            this.state.selectedMealSchedule[dayScheduleIndex].daySchedule.push(meal);
        }
        this.setState({
            selectedMealSchedule: this.state.selectedMealSchedule
        });
    }
    handleMealsDropdownChange(event, selected){
        /*if (selected == this.state.selectedPatientMeal.type)
            this.state.selectedPatientMeal = new Meal(event.target.value, new Date());
        else*/
            this.state.selectedMealType = event.target.value;
        this.setState({
            //selectedPatientMeal: new Meal(event.target.value, new Date())
            selectedMealType: this.state.selectedMealType
        });
        this.loadDietOfSelectedMealType(); // for all patients
    }
    loadDietOfSelectedMealType(){
        let index = this.findMealTypeInArray(this.state.selectedMealType);
        if (index == -1){
            this.state.selectedMealSchedule = [];
        } else{
            this.state.selectedMealSchedule = this.state.meals[index].mealSchedule;
        }
        this.setState({
            selectedMealSchedule: this.state.selectedMealSchedule
        });
    }
    createMealsDropdown(description, selected){
        const listItems = this.state.mealTypes.map((d) => <option key={d} value={d}>{d}</option>);
        return <select style={{fontWeight: 'bold', minWidth: 270}} value={selected} onChange={(event)=>this.handleMealsDropdownChange(event, selected)}>
                    {listItems}
            </select>
    }
    /*savePatientMealsBtn(){
        if (this.props.patient == null){
            return;
        }
        this.state.patient.meal.push(this.state.selectedPatientMeal);
        this.setState({patient: this.state.patient});
        axios.put(url+"/patient",
        this.state.patient,{})
        .then(function (patient) {
            console.log(patient);
        }).catch(err=>{
            console.log(err);
        });
    }*/
    saveMealScheduleBtn(){
        if (this.state.selectedMealType == ''){
            return alert("ΕΠΙΛΕΞΤΕ ΤΥΠΟ ΓΕΥΜΑΤΟΣ");
        }
        let index = this.findMealTypeInArray(this.state.selectedMealType);
        let newMealTypeSchedule = new Meals(this.state.selectedMealType,this.state.selectedMealSchedule);
        if (index == -1){
            this.state.meals.push(newMealTypeSchedule);
            index = this.state.meals.length - 1;
        } else {
            this.state.meals[index].mealSchedule = this.state.selectedMealSchedule;
        }
        this.setState({
            meals: this.state.meals
        });

        this.sendMeals(index); // send index of type of meals saved
    }
    findMealTypeInArray(mealType){
        let meals = this.state.meals;
        for (let i=0;i<meals.length;i++){
            if (meals[i].type == mealType)
                return i;
        }
        // if not found in array
        return -1;
    }
    // sendsMeals for selected type of meal ONLY!!!
    sendMeals(index){
        console.warn("send meals:");
        console.warn(this.state.meals);
        axios.put(url+"/meals",
            this.state.meals[index],{headers: {"Authorization": localStorage.getItem("token")}})
            .then(function (meals) {
                console.log(meals);
                //let meals = sendMeals(patient);
                //send to rea
                //sendMeals(patient.data);
            }).catch(err=>{
                console.log(err);
            });
    }
    createDeleteBtn(j, index){
        return <button className="iconOnlyBtn" style={{marginTop:0}} onClick={()=>{
            var nextState = this.state;
            nextState.selectedMealSchedule[j].daySchedule.splice(index, 1);
            this.setState(nextState);
            }}>
            <img className="imgBtn" src={require('../images/delete.png')}></img></button>
    }

    render() {
        return ( <div className="flex-container">
            <div className="pageHead">
                <img className="dietImage" src={require('../images/diet_doctor.png')}></img>
                <h3 style={{fontSize:20, marginTop:5}}>Διαιτολόγιο</h3>
            </div>
            {/* <div>
                <b style={{fontSize:20, marginTop:5}}>Τύπος Γευμάτων Ασθενή:&nbsp;</b>
                {this.createMealsDropdown(this.state.selectedPatientMeal.type)}
                <button className="addBtn" style={{marginLeft: 5, fontSize:20, fontWeight:"bold", textAlign:"center"}} onClick={() =>{
                    this.savePatientMealsBtn();}}>ΑΠΟΘΗΚΕΥΣΗ
                </button>
            </div> */}
            <div className="pageHead">
                <b style={{fontSize:20, marginTop:5}}>ΔΙΑΙΤΟΛΟΓΙΟ ΤΥΠΟΥ ΓΕΥΜΑΤΟΣ (ΟΛΩΝ ΤΩΝ ΑΣΘΕΝΩΝ):&nbsp;</b>
                {this.createMealsDropdown(this.state.selectedMealType)}
            </div>
            <table className="dietTable">
                <thead>
                <tr>
                    <th></th>
                </tr>
                </thead>
                {this.state.days.map((day,index)=>this.createSchedule(day), this)}
            </table>

            <button className="addBtn" style={{fontSize:20, fontWeight:"bold", textAlign:"center"}}
            onClick={()=>{
                this.saveMealScheduleBtn();
            }}>ΑΠΟΘΗΚΕΥΣΗ</button>
        </div>
        );
    }
}

export default PatientInputMedicine;
