import React from 'react';
import axios from 'axios';
import '../css/Switches.css';
import {updateSwitches} from '../SwitchFunctions';

class Switches extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSwitch: {},
            thermPosition: 0,
            action:0,
            switches: [],
            thermostats: [] 
        };
        this.changeSwitchState = this.changeSwitchState.bind(this);
        // this.selectSwitch = this
        //     .selectSwitch
        //     .bind(this);
        // this.getColor = this
        //     .getColor
        //     .bind(this);
        this.changeLink = "https://hm-api.entranet.us:7656/housemate/home/switchUpdate";
    }

    // changeSwitchState(id) {
    //     let building = this.props.building;
    //     let index = building.switches.findIndex(swit => { return swit.id === id });
    //     if (index < 0) return;
    //     let aSwitch = building.switches[index];
    //     aSwitch.state = aSwitch.state === 1 ? 0 : 1;
    //     this.props.updateState({ selectedBuilding: building });

    //     axios.post(this.changeLink, {
    //         token: this.props.token,
    //         buildingID: this.props.buildingID,
    //         origin: 1,
    //         switch: aSwitch
    //     });
    // }
    changeSwitchState = (aSwitch) => {
        aSwitch.state = aSwitch.state == 1 ? 0 : 1;
        this.setState({ selectedSwitch: aSwitch });

        updateSwitches(aSwitch).then((responseData) => {
            console.log("Update switch then");
        }).catch((error) => {});
    }

    // selectSwitch(aSwitch) {
    //     this.setState({ selectedSwitch: aSwitch });
    // }

    // getColor(switchState) {
    //     if (switchState === 'on') {
    //         return 'blue';
    //     } else {
    //         return 'red';
    //     }
    // }

    render() {
        return this
            .props
            .building.switches.filter((aSwitch) => {
                return aSwitch.type === 'switch';
            })
            .map((aSwitch) => {
                return <span className="smallWidth flex120 viewBlock flexCol  flexAlignCenter"
                    style={{
                        color: "orange",
                        height: "196px",
                        marginBottom: "20px"
                       
                    }}
                    key={aSwitch.id}
                    onClick={() => this.changeSwitchState(aSwitch)}>
                    <img alt=""
                        className="myContainerViewImage"
                        style={{
                            filter: (aSwitch.state === 1)
                                ? "grayscale(0%)"
                                : "hue-rotate(170deg) brightness(0.25)",
                        }}
                        src={require('../images/bulb.png')}></img>
                    <span className="myContainerViewWord">{aSwitch.name}</span>
                </span>;
            })



    }
}

export default Switches;
